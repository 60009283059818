import { Button } from "antd";
import Column from "../../../sharedComponents/Column";
import { DateFormat } from "../../../utils/helpers";

export const PayoutColumn = () => [
	{
		title: "Customer Name",
		index: "customerName",
		render: (_, data) => <Column>{data?.customer?.firstName} {data?.customer?.lastName}</Column>,
	},
	{
		title: "Customer phonenumber",
		index: "customerPhoneNumber",
		render: (_, data) => <Column>{data?.customer?.phoneNumber}</Column>,
	},
	{
		title: "Merchant Name",
		index: "merchantName",
		render: (_, data) => <Column>{data?.merchant?.fullname}</Column>,
	},
	{
		title: "Merchant phone number",
		index: "merchantPhoneNumber",
		render: (_, data) => <Column>{data?.merchant?.phone}</Column>,
	},
	{
		title: "Status",
		index: "status",

		render: (_, data) => <Column>{data?.requestStatus}</Column>,
	},
	{
		title: "Amount",
		index: "netAmount",

		render: (_, data) => <Column>{data?.netAmount}</Column>,
	},
	{
		title: "Date Created",
		index: "dateCreated",

		render: (_, data) => <Column>{DateFormat(data?.createdAt)}</Column>,
	},
	// {
	// 	title: "Action",
	// 	index: "action",
	// 	render: (_, data) => (
	// 		<div className="flex items-center gap-4">
	// 			<Button
	// 				type="link"
	// 				className="!text-green-400 px-0"
	// 				ghost
	// 				onClick={() => openModal()}
	// 			>
	// 				Accept
	// 			</Button>
	// 			<Button
	// 				type="link"
	// 				danger
	// 				className="px-0"
	// 				onClick={() => openModal()}
	// 				Reject
	// 			>
	// 				Reject
	// 			</Button>
	// 		</div>
	// 	),
	// },
];

// onClick={() => changeRequestStatus(data.id, "ACCEPTED")}>
// onClick={() => changeRequestStatus(data.id, "REJECTED")}>