import React from "react";
import styles from "./addmerchant.module.css";
import { Formik, Form, Field } from 'formik';
import { useAddMarchant } from "./useAddMarchant";
import Header from "../../sharedComponents/Header"



const AddMarchant = () => {

  const {
    initialValues,
    validationSchema,
    createAccount
  } = useAddMarchant()

  return (
    <div className={`${styles.merchantbg} container-fluid mx-4`}>
      {/* <p className="text-black-900 text-4xl mt-12 ml-3 font-[800]">
        Add Merchant
      </p> */}
      <Header page="Add Merchant" desc="Register Merchant" />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={createAccount}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className="w-full mt-3">
              <div className="flex flex-wrap ">
                <div className="w-1/3 px-3 mb-6 md:mb-0">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    for="firstName">
                    First Name
                  </label>
                  <Field
                    className="appearance-none block w-full bg-[#FAFAFA] border-2 focus:border-gray-300 text-gray-700 border-transparent rounded py-3 px-4 leading-tight focus:outline-0 "
                    id="firstName"
                    name="firstName"
                    type="text"
                    placeholder="John"
                  />
                </div>

                <div className="w-1/3 px-3">
                  <label
                    className="block tracking-wide text-gray-700 text-xs font-bold mb-2"
                    for="lastName">
                    Last Name
                  </label>
                  <Field
                    className="appearance-none block w-full bg-[#FAFAFA] border-2 focus:border-gray-300 text-gray-700 border-transparent rounded py-3 px-4 leading-tight focus:outline-0 "
                    id="lastName"
                    name="lastName"
                    type="text"
                    placeholder="Doe"
                  />
                </div>
                <div className="w-1/3 px-3">
                  <label
                    className="block tracking-wide text-gray-700 text-xs font-bold mb-2"
                    for="email">
                    Email Address
                  </label>
                  <Field
                    className="appearance-none block w-full bg-[#FAFAFA] border-2 focus:border-gray-300 text-gray-700 border-transparent rounded py-3 px-4 leading-tight focus:outline-0 "
                    id="email"
                    name="email"
                    type="text"
                    placeholder="example@email.com"
                  />
                </div>
              </div>
            </div>

            <div className="w-full mt-5">
              <div className="flex flex-wrap mb-2">
                <div className="w-1/3 px-3 mt-12">
                  <label
                    className="block tracking-wide text-gray-700 text-xs font-bold mb-2"
                    for="phone">
                    Phone Number
                  </label>
                  <Field
                    className="appearance-none block w-full bg-[#FAFAFA] border-2 focus:border-gray-300 text-gray-700 border-transparent rounded py-3 px-4 leading-tight focus:outline-0 "
                    id="phone"
                    name="phone"
                    type="text"
                    placeholder="+234 ********"
                  />
                </div>
                <div className="w-1/3 px-3 mt-12">
                  <label
                    className="block tracking-wide text-gray-700 text-xs font-bold mb-2"
                    for="address">
                    Permanent Address
                  </label>
                  <Field
                    className="appearance-none block w-full bg-[#FAFAFA] border-2 focus:border-gray-300 text-gray-700 border-transparent rounded py-3 px-4 leading-tight focus:outline-0 "
                    id="address"
                    name="address"
                    type="text"
                    placeholder="Address"
                  />
                </div>
                <div className="w-1/3 px-3 mt-12">
                  <label
                    className="block tracking-wide text-gray-700 text-xs font-bold mb-2"
                    for="password">
                    Temporary Password (Recommended password already given)
                  </label>
                  <Field
                    className="appearance-none block w-full bg-[#FAFAFA] border-2 focus:border-gray-300 text-gray-700 border-transparent rounded py-3 px-4 leading-tight focus:outline-0 "
                    id="password"
                    name="password"
                    type="password"
                    placeholder="Password"
                  />
                </div>
                <div className="w-1/3 px-3 mt-12">
                  <label
                    className="block tracking-wide text-gray-700 text-xs font-bold mb-2"
                    for="userType">
                    userType
                  </label>
                  <Field
                    className="appearance-none block w-full bg-[#FAFAFA] border-2 focus:border-gray-300 text-gray-700 border-transparent rounded py-3 px-4 leading-tight focus:outline-0 "
                    id="userType"
                    name="userType"
                    type="text"
                    placeholder="MERCHANT"
                  />
                </div>
                <div className="w-1/3 px-3 mt-12">
                  <label
                    className="block tracking-wide text-gray-700 text-xs font-bold mb-2"
                    for="gender">
                    Gender
                  </label>
                  <Field
                    className="appearance-none block w-full bg-[#FAFAFA] border-2 focus:border-gray-300 text-gray-700 border-transparent rounded py-3 px-4 leading-tight focus:outline-0 "
                    id="gender"
                    name="gender"
                    type="text"
                    placeholder="Male || Female || Prefer not to say"
                  />
                </div>
              </div>
            </div>

            <div className="flex flex-row-reverse mt-5 mb-4">
              <button className={`button_solid w-[160px] font-normal`}>
                Add Merchant
              </button>

              <button className={`${styles.cancell} w-[160px] mx-5`}>Cancel</button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AddMarchant;
