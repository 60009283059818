import React from "react";

export default function PaidStatusRow({ isPaid }) {
	return (
		<>
			{isPaid ? (
				<span className="rounded-md bg-[#EAFDEB] text-[#66B286] p-2">Paid</span>
			) : (
				<span className="rounded-md bg-[#FFF2F2] text-[#EE6161] p-2">Not Paid</span>
			)}
		</>
	);
}
