import Column from "../../../sharedComponents/Column";
import Dropdown from "../../../sharedComponents/Dropdown";
import { BsThreeDotsVertical } from "react-icons/bs";

const dropDownOptions = ({ id, navigate }) => [
	{
		label: "View Profile",
		onClick: () => navigate(id),
	},
	{
		label: "Disable account",
		buttonStyle: { color: "red" },
	},
];

export const MerchantColumn = ({ navigate }) => [
	{
		title: "Merchant Name",
		index: "name",
		render: (_, data) => (
			<Column>{`${data?.account?.firstName} ${data?.account?.lastName}`}</Column>
		),
	},
	{
		title: "Phone number",
		index: "phone",
		render: (_, data) => <Column>{data?.account?.phone}</Column>,
	},
	{
		title: "Email",
		index: "email",

		render: (_, data) => <Column>{data?.account?.email}</Column>,
	},
	{ title: "Address", index: "address", render: (_, data) => <p>{data?.account?.address}</p> },
	{
		title: "Status",
		render: (_, data) => (
			<Column>{data?.account?.accountStatus === "ACTIVE" ? "Active" : "Inactive"}</Column>
		),
	},
	{
		title: "Action",
		index: "action",
		render: (_, data) => (
			<Column>
				<Dropdown buttonClass="!mx-0" options={dropDownOptions({ id: data.id, navigate })}>
					<BsThreeDotsVertical size={16} color="#B7BCC2" />
				</Dropdown>
			</Column>
		),
	},
];
