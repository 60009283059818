export default function LoginSecurity() {
  return (
    <div className="p-4 w-full">
      <div className="w-full">
        <p className="font-bold text-[#1F1F1F] text-lg">Securing Account</p>
        <div className="p-4 bg-white flex flex-col w-full mt-2">
          <div className="flex flex-row form-check form-switch gap-x-4">
            <input
              className="form-check-input appearance-none w-9 -ml-10 rounded-full float-left h-5 align-top bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckDefault"
            />
            <div className="flex flex-col">
              <p className="text-[#1F1F1F] font-bold">
                Two-Factor Authentication (2FA)
              </p>
              <p className="text-[#414141]">
                we’ll ask for a login code if we notice an attempted login from
                an unrecognize device or browser
              </p>
            </div>
          </div>
          <div className="flex flex-row form-check form-switch gap-x-4 mt-6">
            <input
              className="form-check-input appearance-none w-9 -ml-10 rounded-full float-left h-5 align-top bg-white bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckDefault"
            />
            <div className="flex flex-col">
              <p className="text-[#1F1F1F] font-bold">Check IP address</p>
              <p className="text-[#414141]">
                When someone try to log in your account from new ip address, you
                will receive a mail to confirm the new ip address
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-12 flex flex-col">
        <p className="font-bold text-lg text-[#1F1F1F]">Password</p>
        <div className="w-full bg-white p-4">
          <div className="form-group mb-6">
            <label
              htmlFor="currPassword"
              className="text-neutral-label text-sm">
              Old Password
            </label>
            <input
              type="text"
              className="form-control max-w-[40rem] block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-primary-100 focus:outline-none"
              id="curr_Password"
              name="currPassword"
              aria-describedby="emailHelp123"
            />
          </div>
          <div className="form-group mb-6">
            <label htmlFor="newPassword" className="text-neutral-label text-sm">
              New Password
            </label>
            <input
              type="text"
              className="form-control max-w-[40rem] block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-primary-100 focus:outline-none"
              id="new_Password"
              name="newPassword"
              aria-describedby="emailHelp123"
            />
          </div>
          <div className="form-group mb-6">
            <label
              htmlFor="confirmPassword"
              className="text-neutral-label text-sm">
              Confirm Password
            </label>
            <input
              type="text"
              className="form-control max-w-[40rem] block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-primary-100 focus:outline-none"
              id="confirm_Password"
              name="confirmPassword"
              aria-describedby="emailHelp123"
            />
            <button
              type="submit"
              className="button_solid bg-primary-100 !py-2 w-fit px-16 mt-6">
              Update Password
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
