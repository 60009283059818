import React from "react";


const Withdraw = () => {
    return (
        <div>
            <form className="w-full max-w-sm mt-12 mx-auto">
          <div className="flex justify-between mb-8">
              <div>
      <p className="font-bold text-2xl">Withdraw Money</p>
      </div>

      <button type="button" className="bg-white p-2 inline-flex items-center  text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
              <span className="sr-only">Close menu</span>
              
              <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
            </div>




        <div className="items-center mb-6">
          <div className="md:w-2/3 my-2">
            <label
              className="text-gray-500 font-bold  pr-4 text-xs"
              for="">
              Ajot Account
            </label>
            <br />
          </div>
          <div className="w-full">
            <input
              className="bg-[#F5F5F5] appearance-none rounded-lg w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="inline-full-name"
              type="number"
              placeholder=""
            />
          </div>
        </div>
        <div className="items-center mb-6">
          <div className="md:w-2/3 my-2">
            <label
              className="text-gray-500 font-bold pr-2 text-xs"
              for="inline-full-name">
              Amount
            </label>
          </div>
          <div className="w-full">
            <input
              className="bg-[#F5F5F5] appearance-none rounded-lg w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="inline-full-name"
              type="number"
              placeholder=""
            />
          </div>
        </div>
        <div className="items-center mb-6">
          <div className="md:w-2/3 my-2">
            <label
              className="text-gray-500 font-bold mb-1 md:mb-0 pr-4 text-xs"
              for="inline-full-name">
              Description (Optional)
            </label>
          </div>
          <div className="w-full">
            <input
              className="bg-[#F5F5F5] appearance-none rounded-lg w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="inline-full-name"
              type="text"
              placeholder=""
            />
          </div>
        </div>
        <div>
          
          <button className={`button_solid md:w-full w-full font-normal`}>
          Send
        </button>
        </div>
        </form>

        </div>
    )
}

export default Withdraw;