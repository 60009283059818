import { Link } from "react-router-dom";
import styles from "./login.module.css";
import Loader from "../../sharedComponents/Loader/Loader";
import LoaderButton from "../../sharedComponents/Loader/LoaderButton";
import { useSelector } from "react-redux";
import { useState } from "react";
import { dispatch } from "../../redux/store";

export default function Login() {
	const { setValue, isLoading, submitForm } = useLogin();

	return (
		<div className={styles.baseContainer}>
			<div className={styles.loginContainer}>
				<h3>Welcome Back👋🏽</h3>
				<p>Please enter your correct details to gain access.</p>

				<form onSubmit={submitForm}>
					<section className={styles.section}>
						<label htmlFor="email" className={styles.label}>
							Email
						</label>
						<input
							type="email"
							id="email"
							data-testid="email-input"
							className={styles.formInput}
							onChange={setValue("email")}
						/>
					</section>
					<section className={styles.section}>
						<label htmlFor="password" className={styles.label}>
							Password
						</label>
						<input
							data-testid="password-input"
							type="password"
							id="password"
							className={styles.formInput}
							onChange={setValue("password")}
						/>
					</section>
					{/* <Link className={styles.forgotPassword} to={"#"}>
						Forgot Password?
					</Link> */}
					<LoaderButton
						className={`${styles.loginButton} mt-14`}
						iconClassName="text-white"
						isLoading={isLoading}>
						Login
					</LoaderButton>
				</form>
			</div>
		</div>
	);
}

const useLogin = () => {
	const [formValues, setFormValues] = useState({});
	const loading = useSelector((state) => state.loading.effects.auth.login);

	const setValue = (key) => {
		return (e) =>
			setFormValues((prev) => ({
				...prev,
				[key]: e.target.value,
			}));
	};

	const submitForm = (e) => {
		e.preventDefault();
		dispatch.auth.login(formValues);
	};

	return {
		setValue,
		submitForm,
		isLoading: loading,
	};
};
