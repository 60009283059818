import React, { useState, useEffect } from 'react';
import { Button, Divider, message } from "antd";
import { useSelector } from "react-redux";
import { dispatch } from "../../../redux/store";
import Skeleton from "../../../sharedComponents/Loader/Skeleton";
import { comma } from '../../../sharedComponents/NairaSymbol';

export default function PaymentModal({ isOpen, onClose, id }) {

    const [records, setRecords] = useState(0);
    const paymentsloading = useSelector((state) => state.loading.effects.app.getTotalPayments);

    async function setDeposit() {
        const result = await dispatch.app.getTotalDeposit(id);
        if (result && result.deposit) {
            setRecords(result.deposit)
        }
        if (!result.deposit) {
            setRecords("No deposit records found")
        }
    }
    async function setWithdrawal() {
        const result = await dispatch.app.getTotalWithrawal(id);
        if (result && result.withdrawal) {
            setRecords(result.withdrawal)
        }
        if (!result.withdrawal) {
            setRecords("No withdrawal records found")
        }
    }

    if (!isOpen) return null;

    return (
        <div className="container fixed inset-0 flex  mx-auto mt-96 px-4 z-50">
            <div className="modal-overlay absolute inset-0 bg-gray-500 opacity-75"></div>
            <div className="modal-container bg-white w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto">
                {/* Your modal content goes here */}
                <div className="modal-content py-4 text-left px-6">
                    <div className="flex justify-between items-center pb-3">
                        <p className="text-lg font-bold">Merchant total transaction</p>
                        <button
                            className="modal-close cursor-pointer z-50"
                            onClick={onClose}
                        >
                            <svg
                                className="fill-current text-black"
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                            >
                                <path
                                    className="heroicon-ui"
                                    d="M14.59 12l2.7 2.71a1 1 0 01-1.42 1.42L13.17 13l-2.71 2.7a1 1 0 01-1.42-1.42L11.76 12l-2.7-2.71a1 1 0 111.42-1.42L13.17 11l2.71-2.7a1 1 0 111.42 1.42L14.59 12z"
                                />
                            </svg>
                        </button>
                    </div>

                    <div className="flex flex-row space-x-4">
                        <Button
                            onClick={setDeposit}
                            className="flex self-center px-4 pb-2"
                        >
                            Deposits
                        </Button>
                        <Button
                            onClick={setWithdrawal}
                            className="flex self-center px-4 pb-2"
                        >
                            Withdrawals
                        </Button>
                    </div>
                    <Divider />
                    <Skeleton loading={paymentsloading}>
                        <h3 className="text-slate-500 text-sm font-bold">&#8358;{comma(records)}</h3>
                    </Skeleton>
                    <div className="flex justify-end pt-2">
                        <button
                            className="px-4 py-2 bg-blue-500 text-white rounded-md"
                            onClick={onClose}
                        >
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
