import { Outlet } from "react-router-dom";

import ProfileLink from "./profile/profileLink";
import Header from "../../../sharedComponents/Header/header";

export default function Settings() {
  return (
    <>
      <Header desc="Personalize your settings here." page="Settings" />
      <div className="flex flex-col">
        <div className="flex px-6 flex-row mt-4 space-x-12 bg-white border-b-[#F7F7F7]">
          <ProfileLink to={"/settings/profile"} name="Profile" />
          <ProfileLink
            to={"/settings/login_security"}
            name="Login & Security"
          />
        </div>
        <div className="flex flex-1 bg-[#F9F9F9] py-8">
          <Outlet />
        </div>
      </div>
    </>
  );
}
