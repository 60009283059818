import { AiOutlineCheckCircle } from "react-icons/ai";

export default function SuccessPage() {
	return (
		<div className="flex flex-col gap-4 justify-center items-center h-screen">
			<AiOutlineCheckCircle size={128} color="#4ADE80" />
			<h2 className="text-2xl font-bold">Payment was successful</h2>
			<p>You can exit the page now</p>
		</div>
	);
}
