import styles from "./header.module.css";
import { BiChevronDown } from "react-icons/bi";
import Dropdown from "../Dropdown";
import { dispatch } from "../../redux/store";

const menuOptions = [
	{ label: "Logout", onClick: () => dispatch.auth.logout(), buttonStyle: { color: "red" } },
];

export default function Header({ page, desc }) {
	return (
		<div className={styles.header}>
			<div className={styles.headerLeft}>
				<h3>{page}</h3>
				{desc && <p>{desc}</p>}
			</div>
			<div className={styles.headerRight}>
				{/* <FaRegCalendarAlt size={20} />
				<BsFillBellFill size={20} /> */}
				<Dropdown options={menuOptions} buttonClass="!px-0">
					<BiChevronDown size={20} />
				</Dropdown>
			</div>
		</div>
	);
}
