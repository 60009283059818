import React from "react";
import Header from "../../../sharedComponents/Header/header";
import CustomTable from "../../../sharedComponents/CustomTable";
import usePayout from "./usePayout";
import { PayoutColumn } from "./PayoutTableColumn";
import RejectionModal from "./RejectionModal";

export default function Payouts() {
	const {
		payouts,
		changeRequestStatus,
		isModalOpen,
		closeModal,
		reason,
		setReason,
		handle,
		requestID
	} = usePayout();


	return (
		<>
			<Header page="Payouts" desc="Manage your merchant payout requests" />
			<RejectionModal
				isOpen={isModalOpen}
				onClose={closeModal}
				reason={reason}
				setReason={setReason}
				id={requestID}
				updateRequest={changeRequestStatus}
			/>
			<div className="flex flex-col items-start flex-1 px-6 py-4 bg-neutral-60">
				<CustomTable
					columns={PayoutColumn()}
					data={payouts}
					onRowClick={(e) => handle(e.id)}
				/>
			</div>
		</>
	);
}
