import persistPlugin from "@rematch/persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
	key: "ajot-rootState",
	whitelist: ["auth"],
	storage,
};

export const persistStorage = persistPlugin(persistConfig);
