import React, { useState, useEffect } from "react";
import Header from "../../../sharedComponents/Header/header";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { FaAngleDoubleRight } from "react-icons/fa";
// import MerchantInfoCard from "./MerchantInfoCard";
import { Merchant } from "../../../classes/Merchant";
import { useNavigate, useParams } from "react-router-dom";
import { dispatch } from "../../../redux/store";
import { ImLocation } from "react-icons/im";
import { BsTelephoneFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import LoaderButton from "../../../sharedComponents/Loader/LoaderButton";
import CustomTable from "../../../sharedComponents/CustomTable";
import { CustomerColumn } from "../../customer/CustomerColumn";
import { ContributorColumn } from '../contributors/ContributorColumn'
import Skeleton from "../../../sharedComponents/Loader/Skeleton";
import { useSelector } from "react-redux";
import { Button, Divider, message } from "antd";
import KycComponent from "../../../sharedComponents/KycComponent";
import PaymentModal from "./PaymentModal"


export default function MerchantPage() {
	const {
		merchantDetails,
		navigateToContributor,
		goBack,
		loading,
		getKycInfo,
		kycData,
		verifyBvn,
		isKycVerificationOptionsShown,
		contributors,
		accID
	} = useMerchantPage();

	const { account } = merchantDetails;
	const { address, email, lastName, firstName, phone } = account;

	const [isModalOpen, setIsModalOpen] = useState(false);

	const openModal = () => {
		setIsModalOpen(true);
	};

	const closeModal = () => {
		setIsModalOpen(false);
	};

	return (
		<div className="flex flex-1 flex-col">
			<Header page="Merchant" desc="Manage your merchants all in one place" />

			<PaymentModal isOpen={isModalOpen} onClose={closeModal} id={accID} />
			<div className="grid grid-cols-4 w-full bg-[#F5F5F5]">
				<div className="flex flex-col col-span-3 bg-white">
					<div className="flex flex-row">
						<div className="flex flex-row space-x-4 items-center h-auto p-6">
							<span onClick={goBack} className="flex space-x-2 items-center">
								<MdOutlineKeyboardBackspace size={24} />
								<h3 className="font-semibold text-neutral-900 text-xl">Merchant</h3>
							</span>
							<FaAngleDoubleRight size={24} />
							<Skeleton loading={loading}>
								<h3 className="font-semibold text-slate-500 text-xl">{`${firstName} ${lastName}`}</h3>
							</Skeleton>
						</div>
					</div>
					<div className="flex flex-col p-4">
						{!isKycVerificationOptionsShown && (
							<Button onClick={getKycInfo} className="flex self-center px-4 pb-2">
								Get KYC Data
							</Button>
						)}
						<div className="bg-white py-4 px-6 rounded-[5px] my-5 gap-y-6 md:gap-0 flex flex-col md:flex-row items-start justify-between md:items-center">
							<KycComponent title="First Name" data={kycData.first_name} />
							<KycComponent title="Last Name" data={kycData.last_name} />
							<KycComponent title="Middle Name" data={kycData.middle_name} />
							<KycComponent title="Phone Number" data={kycData.phone_number} />
							<KycComponent title="Gender" data={kycData.gender} />
							<KycComponent title="Address" data={kycData.address} />
						</div>
						{isKycVerificationOptionsShown && (
							<>
								<div className="flex justify-end gap-4">
									<Button
										onClick={() => verifyBvn(true)}
										type="primary"
										className="bg-green-500">
										Approve
									</Button>
									<Button
										onClick={() => verifyBvn(false)}
										type="primary"
										className="bg-red-500">
										Reject
									</Button>
								</div>
								<Divider />
							</>
						)}
					</div>
					<CustomTable
						columns={ContributorColumn()}
						data={contributors}
						onRowClick={(e) => navigateToContributor(e.customer.id)}
					/>
				</div>
				<div className="col-span-1 p-4">
					<div className=" rounded-md flex justify-center flex-col flex-1 p-4 bg-white">
						<h4 className="font-semibold text-center">{`${firstName} ${lastName}`}</h4>
						<div className="flex flex-col justify-content gap-8 mt-8">
							<div className="flex justify-between">
								<ImLocation />
								<Skeleton loading={loading}>
									<span className="text-sm">{address}</span>
								</Skeleton>
							</div>
							<div className="flex justify-between">
								<BsTelephoneFill />
								<Skeleton loading={loading}>
									<span className="text-sm">{phone}</span>
								</Skeleton>
							</div>
							<div className="flex justify-between">
								<MdEmail />
								<Skeleton loading={loading}>
									<span className="text-sm">{email}</span>
								</Skeleton>
							</div>
						</div>
						<LoaderButton
							className={`mt-[100%] btn-primary text-sm`}
							onClick={openModal}
							isLoading={false}
						//// color={"black"}
						>
							Get merchant total transactions
						</LoaderButton>
						<LoaderButton className={`mt-4 btn-primary`} isLoading={false}>
							Deactivate
						</LoaderButton>
					</div>
				</div>
			</div>
		</div>
	);
}

const useMerchantPage = () => {
	const navigate = useNavigate();
	const { id } = useParams("id");

	const [merchantDetails, setMerchantDetails] = useState(new Merchant());
	const [contributors, setContributors] = useState([])
	const [kycData, setKycData] = useState({});
	const loading = useSelector((state) => state.loading.effects.app.getMerchantByID);


	const isKycVerificationOptionsShown = Object.values(kycData).length > 0;

	const accID = merchantDetails && merchantDetails.account.id

	const getMerchantByID = () => {
		dispatch.app.getMerchantByID(id).then(res => {
			res && setMerchantDetails(res)
			const accID = res.account.id

			dispatch.app.getMerchantContributors(accID).then(res => {
				res && setContributors(res)
			})
		})
	};


	async function getKycInfo() {
		const response = await dispatch.app.initiateBVN(id);

		if (response) setKycData(response);
	}

	async function verifyBvn(value) {
		const response = await dispatch.app.verifyBvN({
			user: id,
			bvnStatus: value,
		});

		if (response) {
			message.success("KYC state updated");
			return;
		}

		message.error("Something went wrong");
	}


	const navigateToContributor = (id) => {
		navigate(`/contributorPage/${id}`);
	};

	const goBack = () => navigate(-1);

	useEffect(() => {
		getMerchantByID()
	}, []);

	return {
		merchantDetails,
		navigateToContributor,
		goBack,
		loading,
		getKycInfo,
		kycData,
		verifyBvn,
		isKycVerificationOptionsShown,
		contributors,
		accID,
	};
};
