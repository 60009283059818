import { useState, useEffect } from 'react'
import { useSelector } from "react-redux";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  LineElement,
  PointElement,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar, Line } from "react-chartjs-2";

import { IoMdPeople } from "react-icons/io";
import {
  BsArrowDownLeft,
  BsCaretDownFill,
  BsArrowUpRight,
} from "react-icons/bs";
import { RiBankFill } from "react-icons/ri";
import { Link } from "react-router-dom";

import PosMerchant from "./posMerchant";
import { posMerchantData } from "./posMerchantData";
import {
  dashBoardMarkingData,
  lineChartData,
  options,
  barChartData,
} from "./dashboardData";
import Header from "../../../sharedComponents/Header/header";
import MarkingRow from "../contributors/markingTableRow";

import Chip from "../../../assets/images/chip.svg";
import MastercardSymbol from "../../../assets/images/mastercard_symbol.svg";
import MastercardSVG from "../../../assets/images/mastercard_svg.svg";
import Skeleton from "../../../sharedComponents/Loader/Skeleton";
import { dispatch } from "../../../redux/store";
import { comma } from '../../../sharedComponents/NairaSymbol';

export default function Dashboard() {

  const {
    totalUser,
    usersLoading,
    totalContributions,
    totalAmount,
    user
  } = useDashBoard()

  const { totalUsers, totalMerchants, totalCustomers } = totalUser
  const { total, merchantTotal, customerTotal } = totalContributions
  const { totalDeposits, totalWithdrawals } = totalAmount
  const { firstName, lastName } = user

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    PointElement,
    LineElement,
    Legend
  );

  const labels = ["Short", "Long"];

  return (
    <>
      <Header page={`Welcome ${firstName} ${lastName}`} desc="Here’s an overview of Ajot" />
      <div className="flex flex-row justify-between bg-neutral-60 px-6 py-4 gap-x-4 overflow-y-auto">
        <div className="flex flex-col overflow-y-auto  w-full min-h-full">
          <div className="flex items-center flex-row justify-between w-full">
            <div className="flex flex-row space-x-4 items-center">
              <div>
                <div className="flex flex-row px-4 py-6 bg-white space-x-4 shadow-sm shadow-cyan-100">
                  <span className="w-10 h-10 self-center bg-[#E1F1FA] rounded-full flex items-center justify-center">
                    <IoMdPeople color="#2E9CDB" size={20} />
                  </span>
                  <div>

                    <p className="text-center text-[#7D8498] text-lg">
                      Total Users
                    </p>

                    <Skeleton loading={usersLoading}>
                      <p className="text-[#030D25] text-lg font-bold text-right mt-2">
                        {totalUsers}
                      </p>
                    </Skeleton>
                  </div>
                </div>
                <div className="flex flex-row">
                  <div className="flex flex-row px-4 py-6 bg-white space-x-4 shadow-sm shadow-cyan-100">
                    <span className="w-10 h-10 self-center bg-[#E1F1FA] rounded-full flex items-center justify-center">
                      <IoMdPeople color="#2E9CDB" size={18} />
                    </span>
                    <div>
                      <p className="text-center text-[#7D8498] text-lg">
                        Merchants
                      </p>
                      <Skeleton loading={usersLoading}>
                        <p className="text-[#030D25] text-lg font-bold text-right mt-2">
                          {totalMerchants}
                        </p>
                      </Skeleton>
                    </div>
                  </div>
                  <div className="flex flex-row px-4 py-6 bg-white space-x-4 shadow-sm shadow-cyan-100">
                    <span className="w-10 h-10 self-center bg-[#E1F1FA] rounded-full flex items-center justify-center">
                      <IoMdPeople color="#2E9CDB" size={18} />
                    </span>
                    <div>
                      <p className="text-center text-[#7D8498] text-lg">
                        Customers
                      </p>
                      <Skeleton loading={usersLoading}>
                        <p className="text-[#030D25] text-lg font-bold text-right mt-2">
                          {totalCustomers}
                        </p>
                      </Skeleton>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col px-4 py-6 bg-white space-x-4 shadow-sm shadow-cyan-100">
                <div className='flex flex-row px-4 py-6 bg-white space-x-4 shadow-sm shadow-cyan-100'>
                  <span className="w-10 h-10 self-center bg-[#E1F1FA] rounded-full flex items-center justify-center">
                    <BsArrowDownLeft
                      color="#2E9CDB"
                      size={20}
                      className="flex self-center bg-[#E1F1FA]"
                    />
                  </span>
                  <div>
                    <p className="text-center text-[#7D8498] text-lg">
                      Total Contributions
                    </p>
                    <Skeleton loading={usersLoading}>
                      <p className="text-[#030D25] text-lg font-bold text-right mt-2">
                        &#8358;{comma(total)}
                      </p>
                    </Skeleton>
                  </div>
                </div>

                <div className='flex flex-row' >
                  <div className="flex flex-row px-4 py-6 bg-white space-x-4 shadow-sm shadow-cyan-100">
                    <span className="w-8 h-8 self-center bg-[#E1F1FA] rounded-full flex items-center justify-center">
                      <BsArrowDownLeft
                        color="#2E9CDB"
                        size={16}
                        className="flex self-center bg-[#E1F1FA]"
                      />
                    </span>
                    <div>
                      <p className="text-center text-[#7D8498] text-lg">
                        Merchant Total
                      </p>
                      <Skeleton loading={usersLoading}>
                        <p className="text-[#030D25] text-lg font-bold text-right mt-2">
                          &#8358;{comma(merchantTotal)}
                        </p>
                      </Skeleton>
                    </div>
                  </div>

                  <div className="flex flex-row px-4 py-6 bg-white space-x-4 shadow-sm shadow-cyan-100">
                    <span className="w-8 h-8 self-center bg-[#E1F1FA] rounded-full flex items-center justify-center">
                      <BsArrowDownLeft
                        color="#2E9CDB"
                        size={16}
                        className="flex self-center bg-[#E1F1FA]"
                      />
                    </span>
                    <div>
                      <p className="text-center text-[#7D8498] text-lg">
                        Customer Total
                      </p>
                      <Skeleton loading={usersLoading}>
                        <p className="text-[#030D25] text-lg font-bold text-right mt-2">
                          &#8358;{comma(customerTotal)}
                        </p>
                      </Skeleton>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-row space-x-8">
                <div className="flex flex-row px-4 py-6 bg-white space-x-8 shadow-sm shadow-cyan-100">
                  <span className="w-10 h-10 self-center bg-[#E1F1FA] rounded-full flex items-center justify-center">
                    <BsArrowUpRight
                      color="#2E9CDB"
                      size={18}
                      className="flex self-center bg-[#E1F1FA]"
                    />
                  </span>
                  <div>
                    <p className="text-center text-[#7D8498] text-lg">
                      Total Deposits
                    </p>
                    <Skeleton loading={usersLoading}>
                      <p className="text-[#030D25] text-lg font-bold text-right mt-2">
                        &#8358;{comma(totalDeposits)}
                      </p>
                    </Skeleton>
                  </div>
                </div>
                <div className="flex flex-row px-4 py-6 bg-white space-x-4 shadow-sm shadow-cyan-100">
                  <span className="w-10 h-10 self-center bg-[#E1F1FA] rounded-full flex items-center justify-center">
                    <BsArrowUpRight
                      color="#2E9CDB"
                      size={18}
                      className="flex self-center bg-[#E1F1FA]"
                    />
                  </span>
                  <div>
                    <p className="text-center text-[#7D8498] text-lg">
                      Total Withdrawals
                    </p>
                    <Skeleton loading={usersLoading}>
                      <p className="text-[#030D25] text-lg font-bold text-right mt-2">
                        &#8358;{comma(totalWithdrawals)}
                      </p>
                    </Skeleton>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="flex flex-row justify-between mt-6 w-full px-4">
            <p className="text-[#091E42] font-bold">Marking List (4/320)</p>
            <Link to="/marking" className="text-primary-100 text-sm">
              View all
            </Link>
          </div> */}
          {/* !Table to be replaced */}
          {/* <div>
            <div className="overflow-x-auto w-full mt-4">
              <table className=" whitespace-nowrap w-full">
                <thead className="border-b">
                  <tr>
                    <th
                      scope="col"
                      className="text-sm font-bold text-gray-900 px-4 w-40 py-4 text-left">
                      ID
                    </th>
                    <th
                      scope="col"
                      className="text-sm font-bold text-gray-900 px-4 w-40 py-4 text-left">
                      Name
                    </th>
                    <th
                      scope="col"
                      className="text-sm font-bold text-gray-900 px-4 w-10 py-4 text-left">
                      Ajot Plan
                    </th>
                    <th
                      scope="col"
                      className="text-sm font-bold text-gray-900 px-4 w-10 py-4 text-left">
                      Duration
                    </th>
                    <th
                      scope="col"
                      className="text-sm font-bold text-gray-900 px-4 w-16 py-4 text-left">
                      Amount
                    </th>
                    <th
                      scope="col"
                      className="text-sm font-bold text-gray-900 px-4 w-16 py-4 text-left">
                      Date
                    </th>
                    <th
                      scope="col"
                      className="text-sm font-bold text-gray-900 px-4 w-12 py-4 text-left">
                      Status
                    </th>
                    <th
                      scope="col"
                      className="text-sm font-bold text-gray-900 px-4 w-10 py-4 text-center">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {dashBoardMarkingData.map((e) => (
                    <MarkingRow {...e} key={e.id} checkbox={false} />
                  ))}
                </tbody>
              </table>
            </div>
          </div> */}
          {/* Chart goes here */}
        </div>
        {/* <div className="w-1/4 bg-white flex flex-col p-2 overflow-y-auto min-w-[300px]">
          <div className="w-full bg-primary-100 rounded-lg relative h-52 min-h-[13rem]  flex items-center overflow-hidden">
            <div className="flex w-full justify-center items-center gap-x-4">
              <p className="text-white text-lg tracking-widest">****</p>
              <p className="text-white text-lg tracking-widest">****</p>
              <p className="text-white text-lg tracking-widest">****</p>
              <p className="text-white text-lg">3947</p>
            </div>
            <div className="absolute bottom-4 left-2 w-full justify-start space-x-12 z-50 flex-row flex">
              <div>
                <p className="text-white text-[10px]">Cardholder Name</p>
                <p className="text-white text-xs font-bold">Eric Lanister</p>
              </div>
              <div>
                <p className="text-white text-[10px]">Expiry Date</p>
                <p className="text-white text-xs font-bold">05/23</p>
              </div>
              <div></div>
            </div>
            <img src={Chip} alt="" className="absolute w-8 h-6 top-6 left-2" />
            <img
              src={MastercardSymbol}
              alt=""
              className="absolute w-8 h-6 bottom-6 right-2"
            />
            <img
              src={MastercardSVG}
              alt=""
              className="absolute -left-4 -bottom-4"
            />
          </div>
          <div className="w-full flex flex-row justify-center gap-x-8 mt-8">
            <span className="w-10 h-10 rounded-md bg-[#FFF2F2] flex justify-center items-center">
              <BsArrowUpRight size={20} color="#EE6161" />
            </span>
            <span className="w-10 h-10 rounded-md bg-[#E3E7EC] flex justify-center items-center">
              <RiBankFill size={20} color="#20242E" />
            </span>
          </div>
          <h4 className="mt-8 font-bold text-black text-sm ">POS Merchant</h4>
          {posMerchantData.map((item) => (
            <PosMerchant {...item} />
          ))}
          <button className="button_solid !py-2 !w-fit mx-auto px-4 text-sm">
            View all
          </button>
        </div> */}
      </div>
    </>
  );
}

function useDashBoard() {

  const [totalUser, setTotalUsers] = useState({
    totalUsers: 0,
    totalMerchants: 0,
    totalCustomers: 0
  })
  const [totalContributions, setTotalContributions] = useState({
    total: 0,
    merchantTotal: 0,
    customerTotal: 0
  })

  const [totalAmount, setTotalAmount] = useState({
    totalDeposits: 0,
    totalWithdrawals: 0
  })
  const usersLoading = useSelector((state) => state.loading.effects.app.getDashboardUsers);

  const { user } = useSelector(state => state.auth)

  async function getUsers() {
    const result = await dispatch.app.getDashboardUsers()
    result && setTotalUsers({
      totalUsers: result.totalUsers,
      totalMerchants: result.totalMerchants,
      totalCustomers: result.totalCustomers
    })
  }
  async function getContributions() {
    const result = await dispatch.app.getDashboardContributions()
    result && setTotalContributions({
      total: result.total,
      merchantTotal: result.merchantTotal,
      customerTotal: result.customerTotal
    })
  }

  async function getAmount() {
    const result = await dispatch.app.getDashboardAmount()
    result && setTotalAmount({
      totalDeposits: result.totalDeposits,
      totalWithdrawals: result.totalWithdrawals
    })
  }

  useEffect(() => {
    getUsers()
    getContributions()
    getAmount()
  }, [])

  return {
    totalUser,
    usersLoading,
    totalContributions,
    totalAmount,
    user
  }
}