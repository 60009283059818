import { TableColumn } from "../classes/TableColumn";
import { format } from "date-fns";
import { AJOT_STORAGE_KEY } from "./constants";

export const getToken = () => {
	return localStorage.getItem(AJOT_STORAGE_KEY);
};

export const saveToken = (token) => {
	localStorage.setItem(AJOT_STORAGE_KEY, token);
};

export const deleteToken = () => {
	localStorage.removeItem(AJOT_STORAGE_KEY);
};

/**
 *
 * @param {any[]} columns
 * @returns
 */
export const ParseTableColumn = (columns) => {
	return columns.map((column) => new TableColumn(column));
};

/**
 *
 * @param {any[]} data
 * @returns
 */
export const ParseTableData = (data) => {
	if (!data) return [];
	return data.map((el) => ({ ...el, key: el?.id }));
};

export function DateFormat(date) {
	if (!date) return "No date";
	return format(date, 'dd MMMM yyyy');
}