
import axios from "axios";
import { dispatch } from "../redux/store";
//prod
export const BASE_URL = "https://49k6gpy09i.execute-api.eu-central-1.amazonaws.com/prod/api/";

//dev
// export const BASE_URL = "https://0q9itvv1w2.execute-api.eu-central-1.amazonaws.com/dev/api/";

function handleRequest(req) {
	// console.log("handle request");
	req.headers["Content-Type"] = "application/json";
	req.headers.Accept = "application/json";

	return req;
}

async function handleResponse(res) {
	// console.log("handle Response");

	return res;
}

// axios.defaults.timeout = 10000;
// axios.defaults.timeoutErrorMessage = 'Connection Timed out';

axios.interceptors.request.use(
	async (req) => handleRequest(req),
	(error) => Promise.reject(error)
);

axios.interceptors.response.use(
	async (res) => {
		if (res.data?.message === "User is not confirmed.") {
			return { data: "no-verify" };
		}

		if (res.data?.status === 401 || res.status === 401) {
			//! get better solution
			dispatch.auth.logout();
			return Promise.reject({ message: "Session timed out" });
		}

		if (res.data?.status >= 400 || res.status > 400) {
			return Promise.reject(res.data);
		}

		return res.data;
	},
	(err) => {
		console.log({ error: err });

		return Promise.reject(err);
	}
);

export default {
	post: async (url, data, options) =>
		axios.post(options?.fullPath ? url : BASE_URL + url, data, options && { headers: options }),
	patch: async (url, data, options) =>
		axios.patch(
			options?.fullPath ? url : BASE_URL + url,
			data,
			options && { headers: options }
		),
	put: async (url, data, options) =>
		axios.put(options?.fullPath ? url : BASE_URL + url, data, options && { headers: options }),
	delete: async (url, data, options) => {
		data = data ? (data instanceof Object && !Object.keys(data).length ? null : data) : null;
		const config = data ? { headers: options, data } : { headers: options };
		return axios.delete(options?.fullPath ? url : BASE_URL + url, config);
	},
	get: async (url, params, options) => {
		params = params
			? params instanceof Object && !Object.keys(params).length
				? null
				: params
			: null;
		const config = params ? { headers: options, params } : { headers: options };
		return axios.get(options?.fullPath ? url : BASE_URL + url, config);
	},
};
