import { useState } from 'react'
import { Button } from "antd";


export default function RejectionModal({ isOpen, onClose, reason, setReason, id, updateRequest }) {

    const [showForm, setShowForm] = useState(false)
    const handleInputChange = (e) => {
        setReason(e.target.value);
    };

    if (!isOpen) return null;
    return (
        <>
            <div className="container fixed inset-0 flex  mx-auto mt-96 px-4 z-50">
                <div className="modal-overlay absolute inset-0 bg-gray-500 opacity-75"></div>
                <div className="modal-container bg-white w-500 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto">
                    {/* modal content goes here */}
                    <div className="modal-content py-4 text-left px-6">
                        <div className="flex justify-between items-center pb-3">
                            <p className="text-lg font-bold">Decision Modal</p>
                            <button
                                className="modal-close cursor-pointer z-50"
                                onClick={onClose}
                            >
                                <svg
                                    className="fill-current text-black"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="18"
                                    height="18"
                                    viewBox="0 0 18 18"
                                >
                                    <path
                                        className="heroicon-ui"
                                        d="M14.59 12l2.7 2.71a1 1 0 01-1.42 1.42L13.17 13l-2.71 2.7a1 1 0 01-1.42-1.42L11.76 12l-2.7-2.71a1 1 0 111.42-1.42L13.17 11l2.71-2.7a1 1 0 111.42 1.42L14.59 12z"
                                    />
                                </svg>
                            </button>
                        </div>
                    </div>
                    <div className="flex flex-row space-x-4">
                        <Button
                            onClick={() => {
                                updateRequest(id, "APPROVED")
                            }}
                            className="flex self-center px-4 pb-2"
                        >
                            Approve
                        </Button>
                        <Button
                            onClick={() => setShowForm(true)}
                            className="flex self-center px-4 pb-2"
                        >
                            Reject
                        </Button>
                    </div>
                    {
                        showForm && (
                            <form>
                                <textarea
                                    type="text"
                                    value={reason}
                                    onChange={handleInputChange}
                                    placeholder="Send message for rejection reason"
                                    rows="4"
                                    column="50"
                                    className='px-3 py-4 border rounded-md flex flex-1'
                                />
                                <Button
                                    onClick={() => {
                                        setShowForm(false)
                                        onClose()
                                        updateRequest(id, "REJECTED", reason)
                                    }}
                                    className="flex self-center px-4 pb-2"
                                >
                                    Send Reason
                                </Button>
                            </form>
                        )
                    }
                </div>
            </div>
        </>
    )
}