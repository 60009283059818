import React from "react";
import "rodal/lib/rodal.css";
import Rodal from "rodal";
import { useSelector } from "react-redux";
import { ImSpinner6 } from "react-icons/im";

export default function LoadingModal() {
	const isLoading = useSelector((state) => state.loading.global);
	return (
		<Rodal
			visible={isLoading}
			height={100}
			width={100}
			closeMaskOnClick={false}
			onClose={() => {}}
			showCloseButton={false}
			className="bg-transparent p-0 flex items-center justify-center flex-1">
			<div className="flex flex-1 items-center justify-center">
				<span className="animate-spin">
					<ImSpinner6 size={36} className="text-primary-100 spin" data-testid="loading" />
				</span>
			</div>
		</Rodal>
	);
}
