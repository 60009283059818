import { BsThreeDotsVertical } from "react-icons/bs";
import PaidStatusRow from "../../../sharedComponents/PaidStatusRow";
import Column from "../../../sharedComponents/Column";
import { DateFormat } from "../../../utils/helpers";
import Dropdown from "../../../sharedComponents/Dropdown";
import { comma } from '../../../sharedComponents/NairaSymbol';

// const dropDownOptions = ({ id }) => [
// 	// {
// 	// 	label: "View Profile",
// 	// 	onClick: () => navigate(id),
// 	// },
// ];

/**
 * @typedef {import("../../../classes/Contributor").Contributor} Contributor
 */

/**
 * @typedef {Object} ColumnType
 * @property {string} title
 * @property {string} index
 * @property {function(string, Contributor): JSX.Element} render - A function that returns a JSX element, taking in an ObjectA as a prop
 */

/**
 * @function
 * @return {ColumnType[]}
 */
export const ContributorColumn = () => [
	{
		title: "Contributor's Name",
		index: "name",
		render: (_, data) => <Column>{data.customer.firstName} {data.customer.lastName}</Column>,
	},
	{
		title: "Ajot Plan",
		index: "ajotPlan",
		render: (_, data) => <Column>{data.contributionType}</Column>,
	},
	{
		title: "Duration",
		index: "duration",
		render: (_, data) => (
			<Column>
				{data.contributionLimit} {data.targetDuration}
			</Column>
		),
	},
	{
		title: "Amount",
		index: "amount",
		render: (_, data) => <Column>&#8358;{comma(data.amount)}/day</Column>,
	},
	{
		title: "Status",
		index: "status",
		render: (_, data) => (
			<Column>
				<PaidStatusRow isPaid={data.status} />
			</Column>
		),
	},
	{
		title: "Start Date",
		index: "startedAt",
		render: (_, data) => <Column>{DateFormat(data.startDate)}</Column>,
	},
	{
		title: "End Date",
		index: "endAt",
		render: (_, data) => <Column>{DateFormat(data.maturityDate)}</Column>,
	},
	// {
	// 	title: "Action",
	// 	index: "action",
	// 	render: (_, data) => (
	// 		<Column>
	// 			<Dropdown buttonClass="!mx-0" options={dropDownOptions({ id: data.id })}>
	// 				<BsThreeDotsVertical size={16} color="#B7BCC2" />
	// 			</Dropdown>
	// 		</Column>
	// 	),
	// },
];
