import { Link, useMatch, useResolvedPath } from "react-router-dom";
import { FaHandshake, FaUserAlt } from "react-icons/fa";
import { MdDashboard, MdContactSupport } from "react-icons/md";
import { IoIosSettings } from "react-icons/io";
import { IoDocumentText } from "react-icons/io5";
import { IoPeopleSharp } from "react-icons/io5";
import { RiExchangeFill } from "react-icons/ri";
import { HiUsers } from "react-icons/hi";
import { HiIdentification } from "react-icons/hi"

import styles from "./sidebar.module.css";

const iconProps = {
	color: "#B3BAC5",
	size: 16,
};

const activeProps = {
	color: "#2D9CDB",
	size: 16,
};

export default function CustomLink({ children, to, name, ...props }) {
	const resolved = useResolvedPath(to);
	const match = useMatch({ path: resolved.pathname, end: false });

	let Icon;

	switch (to) {
		case "Dashboard":
			Icon = <MdDashboard color={match ? activeProps.color : iconProps.color} size={16} />;
			break;
		case "Contributor":
			Icon = <FaHandshake color={match ? activeProps.color : iconProps.color} size={16} />;
			break;
		case "Transactions":
			Icon = <RiExchangeFill color={match ? activeProps.color : iconProps.color} size={16} />;
			break;
		case "Merchant":
			Icon = <FaUserAlt color={match ? activeProps.color : iconProps.color} size={16} />;
			break;
		case "Payouts":
			Icon = <FaUserAlt color={match ? activeProps.color : iconProps.color} size={16} />;
			break;
		case "Customer":
			Icon = <HiUsers color={match ? activeProps.color : iconProps.color} size={16} />;
			break;
		case "Closebooks":
			Icon = <HiIdentification color={match ? activeProps.color : iconProps.color} size={16} />;
			break;
		case "ManualClosebooks":
			Icon = <HiIdentification color={match ? activeProps.color : iconProps.color} size={16} />;
			break;
		case "waitlists":
			Icon = <IoPeopleSharp color={match ? activeProps.color : iconProps.color} size={16} />;
			break;
		case "Add User":
			Icon = <FaUserAlt color={match ? activeProps.color : iconProps.color} size={16} />;
			break;
		case "Settings":
			Icon = <IoIosSettings color={match ? activeProps.color : iconProps.color} size={16} />;
			break;
		case "Contact-us":
			Icon = (
				<MdContactSupport color={match ? activeProps.color : iconProps.color} size={16} />
			);
			break;
		case "Terms-of-use":
			Icon = <IoDocumentText color={match ? activeProps.color : iconProps.color} size={16} />;
			break;
		default:
			// eslint-disable-next-line no-unused-vars
			Icon = null;
	}

	return (
		<Link to={"/" + to} className={!match ? styles.link : `${styles.link} ${styles.active}`}>
			{Icon} <p>{name || to.split("-").join(" ")}</p>
		</Link>
	);
}
