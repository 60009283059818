import { AbstractClass } from "./AbstractClass";

export class ContributionHistory extends AbstractClass {
    constructor(props) {
        super(props);
        // this.addedBy = props?.addedBy;
        // this.status = props?.status;
        // this.contributionLimit = props?.contributionLimit;
        // this.maturityDate = props?.maturityDate;
        // this.contributionType = props?.contributionType;
        // this.history = props?.history || [];
        // this.startDate = props?.startDate;
        // this.amount = props?.amount;
        // this.customer = props?.customer;
        // this.targetDuration = props?.targetDuration;
        this.createdAt = props?.createdAt;
        this.recordedon = props?.recordedOn || props?.updatedAt;
        this.amount = props?.amount
        this.marked = props?.marked
    }
}
