import { useEffect, useState } from "react";
import Header from "../../sharedComponents/Header";
import { dispatch } from "../../redux/store";
import { useNavigate } from "react-router-dom";
import CustomTable from "../../sharedComponents/CustomTable";
import { PaymentColumn } from "./paymentTable";
import DecisonModal from './DecisonModal'

export default function Payment() {
    const {
        lists,

        isOpen,
        closeModal,
        openModal,
        id,
        records
    } = usePayment()
    return (
        <div>
            <Header page="Bank Transfer Payment Record" desc="All payment record in one place" />
            <DecisonModal isOpen={isOpen} onClose={closeModal} id={id} records={records} />
            <div className="flex flex-col items-start flex-1 px-6 py-4 bg-neutral-60"></div>
            <CustomTable
                onRowClick={(e) => openModal(e.id)}
                columns={PaymentColumn()}
                data={lists}
            />
        </div>
    )
}

const usePayment = () => {
    const [lists, setLists] = useState([]);
    const [isOpen, setIsOpen] = useState(false);

    const [id, setID] = useState("")

    const openModal = (id) => {
        setIsOpen(true);
        setID(id)
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    async function records() {
        const records = await dispatch.app.getManualCloseBooks();
        lists && setLists(records);
    }

    useEffect(() => {
        records()
    }, [])
    return {
        lists,
        isOpen,
        closeModal,
        openModal,
        id,
        records
    }
}

