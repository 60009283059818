import { BsThreeDotsVertical } from "react-icons/bs";
import PaidStatusRow from "../../../sharedComponents/PaidStatusRow";
import Column from "../../../sharedComponents/Column";
import { DateFormat } from "../../../utils/helpers";
import Dropdown from "../../../sharedComponents/Dropdown";
import { MarkedStatusRow } from "../../../sharedComponents/MarkedStatusRow";
import { comma } from '../../../sharedComponents/NairaSymbol';

// const dropDownOptions = ({ id }) => [
// 	// {
// 	// 	label: "View Profile",
// 	// 	onClick: () => navigate(id),
// 	// },
// ];

/**
 * @typedef {import("../../../classes/Contributor").Contributor} Contributor
 */

/**
 * @typedef {Object} ColumnType
 * @property {string} title
 * @property {string} index
 * @property {function(string, Contributor): JSX.Element} render - A function that returns a JSX element, taking in an ObjectA as a prop
 */

/**
 * @function
 * @return {ColumnType[]}
 */
export const ContributorHistoryColumn = () => [
    {
        title: "Date Created",
        index: "createdAt",
        render: (_, data) => <Column>{DateFormat(data?.createdAt)}</Column>,
    },
    {
        title: "Marked Date",
        index: "recordedOn",
        render: (_, data) => <Column>{DateFormat(data?.recordedon)}</Column>,
    },
    {
        title: "Amount",
        index: "amount",
        render: (_, data) => (
            <Column>
                &#8358;{comma(data?.amount)}
            </Column>
        ),
    },
    {
        title: "Marked",
        index: "status",
        render: (_, data) => (
            <Column>
                <MarkedStatusRow isMarked={data.marked} />
            </Column>
        ),
    },
];
