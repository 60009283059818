const KycColumns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name"
  },
  {
    title: "Phone number",
    dataIndex: "phone",
    key: "phone",
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Account Status",
    dataIndex: "accountStatus",
    key: "accountStatus",
    filters: [
      {
        text: 'ACTIVE',
        value: 'ACTIVE',
      },
      {
        text: 'INACTIVE',
        value: 'INACTIVE',
      },
    ],
    onFilter: (value, record) => record.accountStatus.indexOf(value) === 0
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
    filters: [
      {
        text: 'CUSTOMER',
        value: 'CUSTOMER',
      },
      {
        text: 'MERCHANT',
        value: 'MERCHANT',
      },
    ],
    onFilter: (value, record) => record.type.indexOf(value) === 0
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    filters: [
      {
        text: 'pending',
        value: 'pending'
      },
      {
        text: 'approved',
        value: 'approved'
      },
      {
        text: 'rejected',
        value: 'rejected'
      },
    ],
    onFilter: (value, record) => record.status.indexOf(value) === 0,
    
  }
]

export default KycColumns