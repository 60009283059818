import React from "react";
import { FaSpinner } from "react-icons/fa";

/**
 *
 * @param {{containerClass: string, isLoading: boolean}} param0
 * @returns
 */
export default function LoaderButton({
	children,
	iconClassName,
	isLoading = true,
	size = 16,
	color,
	...props
}) {
	return (
		<button {...props}>
			{!isLoading ? (
				children
			) : (
				<div className={`flex flex-1 justify-center items-center bg-${color}`}>
					<div>
						<FaSpinner size={18} className={`${iconClassName} text-primary-100 spin`} />
					</div>
				</div>
			)}
		</button>
	);
}
