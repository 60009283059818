import React from "react";
import { Table } from "antd";
import { ParseTableColumn, ParseTableData } from "../utils/helpers";

export default function CustomTable({ columns, data, ...props }) {
	const columnData = ParseTableColumn(columns);
	const dataSource = ParseTableData(data);

	return (
		<Table
			scroll={{ x: "100%" }}
			className="w-full"
			onRow={(record, rowIndex) => {
				return {
					onClick: ((e) => props.onRowClick(record, rowIndex)) || (() => {}),
				};
			}}
			columns={columnData}
			dataSource={dataSource}
			{...props}
		/>
	);
}
