import { AbstractClass } from "./AbstractClass";

export class Account extends AbstractClass {
	constructor(props) {
		super(props);
		this.accountStatus = props?.accountStatus;
		this.userType = props?.userType;
		this.emailConfirmed = props?.emailConfirmed;
		this.lastName = props?.lastName;
		this.updatedAt = props?.updatedAt;
		this.createdAt = props?.createdAt;
		this.address = props?.address;
		this.email = props?.email;
		this.id = props?.id;
		this.phone = props?.phone;
		this.firstName = props?.firstName;
	}
}
