import React from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Header from "../../sharedComponents/Header/header";

const Account = () => {
    const {
        initialValues,
        validationSchema
    } = useAccount

    return (
        <>
            <Header page="Create Accounts" desc="Register a user here" />
            <div className="max-w-md mx-auto">
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                // onSubmit={campaignRequest}
                >
                    {({ isSubmitting }) => (
                        <Form className='flex flex-col mb:flex-row'>
                            <div>
                                <Field
                                    type="text"
                                    id="firstName"
                                    name="firstName"
                                    placeholder="First name"
                                    className="w-full sm:w-full focus:ring-0 p-4 border-t sm:border-l-0 border-b sm:border-b-0 border-l sm:border-t text-gray-800 focus:outline-none focus:ring-0 border-gray-200 bg-white mb-2 sm:mb-0"
                                />
                                <ErrorMessage name="firstName" component="div" className="error" />

                                <Field
                                    type="text"
                                    id="lastName"
                                    name="lastName"
                                    placeholder="Last name"
                                    className="w-full sm:w-full focus:ring-0 p-4 border-t sm:border-l-0 border-b sm:border-b-0 border-l sm:border-t text-gray-800 focus:outline-none focus:ring-0 border-gray-200 bg-white mb-2 sm:mb-0"
                                />
                                <ErrorMessage name="lastName" component="div" className="error" />

                                <Field
                                    type="text"
                                    id="phone"
                                    name="phone"
                                    placeholder="Phone number"
                                    className="w-full sm:w-full focus:ring-0 p-4 border-t sm:border-l-0 border-b sm:border-b-0 border-l sm.border-t text-gray-800 focus:outline-none focus:ring-0 border-gray-200 bg-white mb-2 sm:mb-0"
                                />
                                <ErrorMessage name="phone" component="div" className="error" />

                                <Field
                                    type="email"
                                    id="email"
                                    name="email"
                                    placeholder="Email address"
                                    className="w-full sm:w-full focus:ring-0 p-4 border-t sm:border-l-0 border-b sm:border-b-0 border-l sm.border-t text-gray-800 focus:outline-none focus:ring-0 border-gray-200 bg-white mb-2 sm:mb-0"
                                />
                                <ErrorMessage name="email" component="div" className="error" />
                                <Field
                                    type="text"
                                    id="address"
                                    name="address"
                                    placeholder="Home Address"
                                    className="w-full sm:w-full focus:ring-0 p-4 border-t sm:border-l-0 border-b sm:border-b-0 border-l sm.border-t text-gray-800 focus:outline-none focus:ring-0 border-gray-200 bg-white mb-2 sm:mb-0"
                                />
                                <ErrorMessage name="email" component="div" className="error" />
                                <Field
                                    type="text"
                                    id="password"
                                    name="password"
                                    placeholder="Password"
                                    className="w-full sm:w-full focus:ring-0 p-4 border-t sm:border-l-0 border-b sm:border-b-0 border-l sm.border-t text-gray-800 focus:outline-none focus:ring-0 border-gray-200 bg-white mb-2 sm:mb-0"
                                />
                                <ErrorMessage name="email" component="div" className="error" />
                                <Field
                                    type="text"
                                    id="userType"
                                    name="userType"
                                    placeholder="MERCHANT or CUSTOMER"
                                    className="w-full sm:w-full focus:ring-0 p-4 border-t sm:border-l-0 border-b sm:border-b-0 border-l sm.border-t text-gray-800 focus:outline-none focus:ring-0 border-gray-200 bg-white mb-2 sm:mb-0"
                                />
                                <ErrorMessage name="email" component="div" className="error" />
                                <Field
                                    type="text"
                                    id="provider"
                                    name="provider"
                                    placeholder="provider"
                                    className="w-full sm:w-full focus:ring-0 p-4 border-t sm:border-l-0 border-b sm:border-b-0 border-l sm.border-t text-gray-800 focus:outline-none focus:ring-0 border-gray-200 bg-white mb-2 sm:mb-0"
                                />
                                <ErrorMessage name="email" component="div" className="error" />
                            </div>
                            {/* <ToastContainer /> */}
                            <button
                                type="submit"
                                className="w-full sm:w-full px-4 bg-primary2 font-bold p-4 uppercase border-primary1 border-t sm:border-t-0 border-b border-r sm:border-r-0"
                                disabled={isSubmitting}
                            >
                                {'Submit'}
                            </button>
                        </Form>
                    )}
                </Formik>
            </div>
        </>
    )
}

export default Account


const useAccount = () => {

    const initialValues = {
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
    };

    const validationSchema = Yup.object().shape({
        firstName: Yup.string().required('First Name is required'),
        lastName: Yup.string().required('Last Name is required'),
        phone: Yup.string().required('Phone Number is required'),
        email: Yup.string().email('Invalid email address').required('Email is required'),
    });

    async function createAccount(values, { resetForm }) {
        // try {
        //     const response = await campaignSubmit(values);

        //     if (response.message === "user created successfully") {
        //         toast.success('Campaign submitted successfully');
        //     } else {
        //         toast.error('Campaign submission failed');
        //     }
        //     resetForm()
        // } catch (error) {
        //     toast.error('An error occurred. Please try again later.');
        //     // throw error;
        // }
    }


    return {
        initialValues,
        validationSchema,
    }
}