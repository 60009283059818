import React from "react";
import { BsCaretDownFill } from "react-icons/bs";

const Deposit = () => {
  return (
    <div>
      <form className="w-full max-w-sm mt-12 mx-auto">
        <div className="flex justify-between mb-8">
          <div>
            <p className="font-bold text-2xl">Deposit Money</p>
          </div>

          <button
            type="button"
            className="bg-white p-2 inline-flex items-center  text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 "
          >
            <span className="sr-only">Close menu</span>

            <svg
              className="h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>

        <div class="w-full">
          <label
            class="block tracking-wide text-gray-700 text-xs font-bold mb-2 text-xs"
            for="grid-last-name"
          >
            Select Bank
          </label>
          <div className="dropdown relative">
            <button
              data-bs-toggle="dropdown"
              aria-expanded="false"
              className={`flex px-4 mt-0 !bg-[#F5F5F5] dropdown_button relative gap-x-2 w-full h-[45px] !rounded-lg flex-row items-center button_solid`}
            >
              <BsCaretDownFill
                size={12}
                color="#B7BCC2"
                className="right-4 absolute top-[18px]"
              />
            </button>
            <ul
              aria-labelledby="dropdownMenuButton1"
              className={`dropdown-menu dropdown_list`}
            >
              <li>
                <button>All</button>
              </li>
              <li>
                <button>Active</button>
              </li>
              <li>
                <button>Inactive</button>
              </li>
            </ul>
          </div>
        </div>
        <div className="items-center mb-6 mt-6">
          <div className="md:w-2/3 my-2">
            <label
              className="block text-gray-500 font-bold mb-1 md:mb-0 pr-2 text-xs"
              for="inline-full-name"
            >
              Account Number
            </label>
          </div>
          <div className="w-full">
            <input
              className="bg-[#F5F5F5] appearance-none rounded-lg w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 "
              id="inline-full-name"
              type="number"
              placeholder=""
            />
          </div>
        </div>
        <div className="items-center mb-6">
          <div className=" my-2">
            <div className="flex justify-between">
              <div>
                <div
                  className="block text-gray-500 font-bold mb-1 md:mb-0 text-xs"
                  for="inline-full-name"
                >
                  Amount
                </div>
              </div>
              <div
                className="block text-gray-500 font-bold mb-1 md:mb-0 text-xs"
                for="inline-full-name"
              >
                Balance:₦12,034,000.43
              </div>
            </div>
          </div>
          <div className="w-full">
            <input
              className="bg-[#F5F5F5] appearance-none rounded-lg w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="inline-full-name"
              type="number"
              placeholder=""
            />
          </div>
        </div>
        <div className="items-center mb-6">
          <div className="md:w-2/3 my-2">
            <label
              className="block text-gray-500 font-bold mb-1 md:mb-0 pr-4 text-xs"
              for="inline-full-name"
            >
              Description (Optional)
            </label>
          </div>
          <div className="w-full">
            <input
              className="bg-[#F5F5F5] appearance-none rounded-lg w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 "
              id="inline-full-name"
              type="text"
              placeholder=""
            />
          </div>
        </div>
        <div>
          <button className={`button_solid md:w-full w-full font-normal`}>
            Send
          </button>
        </div>
      </form>
    </div>
  );
};

export default Deposit;
