import React from "react";

export function MarkedStatusRow({ isMarked }) {
    return (
        <>
            {isMarked ? (
                <span className="rounded-md bg-[#EAFDEB] text-[#66B286] p-2">true</span>
            ) : (
                <span className="rounded-md bg-[#FFF2F2] text-[#EE6161] p-2">false</span>
            )}
        </>
    );
}
export function EmailStatusRow({ isVerified }) {
    return (
        <>
            {isVerified ? (
                <span className="rounded-md bg-[#EAFDEB] text-[#66B286] p-2">true</span>
            ) : (
                <span className="rounded-md bg-[#FFF2F2] text-[#EE6161] p-2">false</span>
            )}
        </>
    );
}


export function ContributionStatusRow({ isOpened }) {
    return (
        <>
            {isOpened ? (
                <span className="rounded-md bg-[#EAFDEB] text-[#66B286] p-2">OPENED</span>
            ) : (
                <span className="rounded-md bg-[#FFF2F2] text-[#EE6161] p-2">CLOSED</span>
            )}
        </>
    );
}
export function BookStatusRow({ status }) {
    return (
        <>
            {
                status === "closed" && (
                    <span className="rounded-md bg-[#EAFDEB] text-[#EE6161] p-2">CLOSED</span>
                )
            }
            {
                status === "opened" && (
                    <span className="rounded-md bg-[#EAFDEB] text-[#66B286] p-2">OPENED</span>
                )
            }
            {
                status === "open" && (
                    <span className="rounded-md bg-[#EAFDEB] text-[#66B286] p-2">OPENED</span>
                )
            }
        </>
    );
}
