import { AbstractClass } from "./AbstractClass";
import { Contribution } from "./Contribution";
// import { Customer } from "./Customer";




class Customer extends AbstractClass {

}
export class Contributor extends AbstractClass {
	constructor(props) {
		super(props);

		// this.recordedBy = props?.recordedBy;
		// this.contributionDate = props?.contributionDate;
		this.contribution = new Contribution(props?.contribution);
		this.amount = props?.amount;
		// this.recordedOn = props?.recordedOn;
		// this.marked = props?.marked;
		// this.merchant = props?.merchant;
		// this.customer = new Customer(props?.customer);
	}
}
