import { AbstractClass } from "./AbstractClass";
import { Account } from "./Account";

export class Merchant extends AbstractClass {
	constructor(props) {
		super(props);
		this.account = new Account(props?.account);
		this.userCode = props?.userCode;
		this.customers = props?.customers || [];
	}
}
