export const reducerActions = {
  setState(state, payload) {
    return {
      ...state, ...payload
    };
  },
  // setError(state, payload) {
  //   return {
  //     ...state,
  //     isServerError: payload,
  //   };
  // },
  clear(state = {}, payload = {}) {
    return null
  },
};
