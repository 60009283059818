import { Link, useMatch, useResolvedPath } from "react-router-dom";
import styles from "./profile.module.css";

export default function ProfileLink({ to, children, name, ...props }) {
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: true });

  return (
    <Link
      to={to}
      className={!match ? styles.link : `${styles.link} ${styles.active}`}>
      <p>{name}</p>
    </Link>
  );
}
