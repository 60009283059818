import { Navigate, Route, Routes } from "react-router-dom";
import Login from "./pages/login/login";
import Getstarted from "./pages/getstarted";
import CreateAccount from "./pages/createaccount/CreateAccount";
import Main from "./sharedComponents/SideBar";
import Merchant from "./pages/subPages/merchant";
import Contributors from "./pages/subPages/contributors";
import AddMarchant from "./pages/addmarchant/AddMarchant";
import Profile from "./pages/subPages/settings/profile";
import LoginSecurity from "./pages/subPages/settings/login_security";
import Transactions from "./pages/subPages/transactions";
import Dashboard from "./pages/subPages/dashboard";
import AllKycComponent from "./pages/subPages/kyc"
import Home from "./pages/Home";
import Waitlist from "./pages/waitlist";
import Account from "./pages/account"

import Settings from "./pages/subPages/settings";
import ChangePassword from "./pages/changepassword/ChangePassword";
import ForgotPassword from "./pages/forgotpassword/ForgotPassword";
import Deposit from "./pages/deposit/Deposit";
import Withdraw from "./pages/withdraw/Withdraw";
import { useSelector } from "react-redux";
import MerchantPage from "./pages/subPages/merchantPage";
import Customers from "./pages/customer/Customers";
import CustomerPage from "./pages/customerPage/CustomerPage";
import Payouts from "./pages/subPages/payouts/Payouts";
import SuccessPage from "./pages/success";
import Contributor from "./pages/subPages/merchantPage/Contributor";
import Closebooks from "./pages/subPages/closedbooks/Closebooks";
import Payment from "./pages/manualPayments/payment";


function RoutesComponent() {
	let user = useSelector((state) => state.auth);

	if (!user)
		return (
			<Routes>
				<Route path="/home" element={<Home />} />
				<Route path="/success" element={<SuccessPage />} />
				<Route path="/login" element={<Login />} />
				<Route path="*" element={<Navigate to="/home" replace />} />
			</Routes>
		);

	return (
		<Routes>
			<Route path="/addmerchant" element={<AddMarchant />} />
			<Route path="/changepassword" element={<ChangePassword />} />
			<Route path="/forgotpassword" element={<ForgotPassword />} />
			<Route path="/deposit" element={<Deposit />} />
			<Route path="/withdraw" element={<Withdraw />} />

			<Route path="/" element={<Main />}>
				<Route path="/" element={<Navigate to="/merchant" replace />} />
				<Route path="/customer" element={<Customers />} />
				<Route path="/customer/:id" element={<CustomerPage />} />
				<Route path="/merchant" element={<Merchant />} />
				<Route path="/merchant/:id" element={<MerchantPage />} />
				<Route path="/contributorPage/:id" element={<Contributor />} />
				<Route path="/dashboard" element={<Dashboard />} />
				<Route path="/closebooks" element={<Closebooks />} />
				<Route path="/manualclosebooks" element={<Payment />} />
				<Route path="/waitlists" element={<Waitlist />} />
				<Route path="/admin-create-account" element={<Account />} />
				<Route path="/payouts" element={<Payouts />} />
				<Route path="/kyc" element={<AllKycComponent />} />
				<Route path="/contributor" element={<Contributors />} />
				<Route path="/transactions" element={<Transactions />} />
				<Route path="/settings" element={<Settings />}>
					<Route path="/settings" element={<Navigate to="/settings/profile" replace />} />
					<Route path="/settings/login_security" element={<LoginSecurity />} />
					<Route path="/settings/profile" element={<Profile />} />
				</Route>
			</Route>
			<Route path="*" element={<Navigate to="/dashboard" replace />} />
		</Routes>
	);
}

export default RoutesComponent;
