export class TableColumn {
	constructor(props) {
		if (typeof props !== "string") {
			this.title = props.title;
			this.dataIndex = props?.index || props.title;
			this.key = props?.key || props.title;
			this.render = props?.render;
		} else {
			this.title = props;
			this.dataIndex = props;
			this.key = props;
		}
	}
}
