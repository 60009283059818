import React from "react";
export default function KycComponent({ title, data }) {
	return (
		<>
			{data && (
				<div>
					<h3 className="px-6">{title}</h3>
					<div className="bg-white py-4 px-6 rounded-[5px] gap-y-6 md:gap-0 flex flex-col md:flex-row items-start justify-between md:items-center">
						<p className="text-[#02084B]">{data}</p>
					</div>
				</div>
			)}
		</>
	);
}
