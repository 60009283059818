import { useEffect, useState } from 'react'
import { ContributionHistory } from "../../classes/ContributionHistory";
import { ContributorHistoryColumn } from '../subPages/merchantPage/ContributionHistoryCol'
import CustomTable from "../../sharedComponents/CustomTable";
import { Button, Divider, message } from "antd";
import Skeleton from "../../sharedComponents/Loader/Skeleton";
import { dispatch } from "../../redux/store";
import { useSelector } from "react-redux";


export default function HistoryTable({ isOpen, onClose, id }) {

    const [contributionHistory, setContributionHistory] = useState([])
    const loading = useSelector((state) => state.loading.effects.app.getContributionCustomerHistory);

    const getCustomerDetails = async () => {
        const history = await dispatch.app.getContributionCustomerHistory(id)
        history && setContributionHistory(history)
    };

    if (!isOpen) return null;
    return (
        <>
            <div className="container fixed inset-0 flex  mx-auto mt-96 px-4 z-50">
                <div className="modal-overlay absolute inset-0 bg-gray-500 opacity-75"></div>
                <div className="modal-container bg-white w-500 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto">
                    {/* Your modal content goes here */}
                    <div className="modal-content py-4 text-left px-6">
                        <div className="flex justify-between items-center pb-3">
                            <p className="text-lg font-bold">Contribution History</p>
                            <button
                                className="modal-close cursor-pointer z-50"
                                onClick={onClose}
                            >
                                <svg
                                    className="fill-current text-black"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="18"
                                    height="18"
                                    viewBox="0 0 18 18"
                                >
                                    <path
                                        className="heroicon-ui"
                                        d="M14.59 12l2.7 2.71a1 1 0 01-1.42 1.42L13.17 13l-2.71 2.7a1 1 0 01-1.42-1.42L11.76 12l-2.7-2.71a1 1 0 111.42-1.42L13.17 11l2.71-2.7a1 1 0 111.42 1.42L14.59 12z"
                                    />
                                </svg>
                            </button>
                        </div>

                    </div>
                    <Button
                        onClick={getCustomerDetails}
                        className="flex self-center px-4 pb-2"
                    >
                        Load Data
                    </Button>

                    {
                        contributionHistory && (
                            <CustomTable
                                columns={ContributorHistoryColumn()}
                                data={contributionHistory}
                            />
                        )
                    }
                </div>
            </div>
        </>
    )
}