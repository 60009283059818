import React from "react";
import styles from './forgotpass.module.css'

const ForgotPassword = () => {
    return (
        <div className={styles.baseContainer} >
          <div className={styles.forgotpassContainer}>
            <form  className='w-full mt-12'>
                <div className="md:w-full">
            <h2 className="mb-8 font-bold text-4xl">Forgot Password</h2>
            <p className={styles.enteremail}>Please enter your email address or phone number and we’ll send you a link to reset your password</p>
            <div className="w-full mb-8">
            <label
              className={styles.address}
              for="grid-last-name">
              Email Address / Phone Number
            </label>
            <input
              className="appearance-none block w-full bg-[#FAFAFA] border-0 focus:border-gray-300 text-gray-700 rounded py-3 px-4 leading-tight focus:outline-0"
              id="grid-last-name"
              type="text"
              placeholder="Email address or Phone number"
            />
          </div>
          <button className={`button_solid font-bold`}>
          Reset Password
        </button>
        <button className={`${styles.cancell} w-full !font-bold`}>Back to Login</button>
        </div>
        </form>
        </div>

        </div>
    )
}

export default ForgotPassword;