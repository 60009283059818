import { BsSearch, BsCaretDownFill, BsPlus } from "react-icons/bs";

import { customerTestData } from "./customerTestData";
import MarkingRow from "./markingTableRow";
import Header from "../../../sharedComponents/Header";
import CustomTable from "../../../sharedComponents/CustomTable";
import { useState } from "react";
import { appAPI } from "../../../services/apis/app";
import { ContributorColumn } from "./ContributorColumn";
import { useEffect } from "react";
import { dispatch } from "../../../redux/store";
import { Contributor } from "../../../classes/Contributor";

export default function Contributors() {
	const { contributors } = useContributors();

	return (
		<>
			<Header page="Contributors" />
			<div className="flex flex-col items-start flex-1 px-6 py-4 bg-neutral-60">
				{/* <div className="flex items-center space-x-8 flex-row justify-between w-full">
          <form method="GET" className="w-full max-w-lg min-w-fit">
            <div className="relative text-[#091E42]">
              <BsSearch className="absolute top-[14px] left-4" />
              <input
                type="search"
                name="q"
                className="py-3 text-xs w-full text-[#B3BAC5] bg-white border-2 border-[#A5ADBA] rounded-md pl-11 pr-4 focus:outline-none focus:bg-white focus:text-gray-900"
                placeholder="Search by merchant name"
                autoComplete="off"
              />
            </div>
          </form>
          <div className="w-fit flex items-center justify-end space-x-4">
            <button
              className={`flex px-4 mt-0 !bg-[#DAF2FF] gap-x-2 w-max py-3 flex-row items-center button_solid`}>
              <p className=" !text-primary-200">Export</p>
              <BsCaretDownFill size={16} color="#2D9CDB" />
            </button>
            <button
              className={`flex px-4 whitespace-nowrap mt-0 pl-2 gap-x-2 button_solid w-max py-3 flex-row button_solid`}>
              <BsPlus size={20} />
              <p>Add Merchant</p>
            </button>
          </div>
        </div> */}

				<CustomTable columns={ContributorColumn()} data={contributors} />
				{/* <div className="overflow-x-auto w-full h-full mt-4">
          <table className=" whitespace-nowrap w-full">
            <thead className="border-b">
              <tr>
                <th
                  scope="col"
                  className="text-sm font-bold text-gray-900 w-10 py-4 flex items-center flex-row text-left">
                  <input
                    className={`form-check-input checkbox `}
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                  />
                </th>
                <th
                  scope="col"
                  className="text-sm font-bold text-gray-900 px-4 w-40 py-4 text-left">
                  Customer ID
                </th>
                <th
                  scope="col"
                  className="text-sm font-bold text-gray-900 px-4 w-40 py-4 text-left">
                  Customer Name
                </th>
                <th
                  scope="col"
                  className="text-sm font-bold text-gray-900 px-4 w-10 py-4 text-left">
                  Ajot Plan
                </th>
                <th
                  scope="col"
                  className="text-sm font-bold text-gray-900 px-4 w-10 py-4 text-left">
                  Duration
                </th>
                <th
                  scope="col"
                  className="text-sm font-bold text-gray-900 px-4 w-16 py-4 text-left">
                  Amount
                </th>
                <th
                  scope="col"
                  className="text-sm font-bold text-gray-900 px-4 w-16 py-4 text-left">
                  Date
                </th>
                <th
                  scope="col"
                  className="text-sm font-bold text-gray-900 px-4 w-12 py-4 text-left">
                  Status
                </th>
                <th
                  scope="col"
                  className="text-sm font-bold text-gray-900 px-4 w-10 py-4 text-center">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {customerTestData.map((e) => (
                <MarkingRow {...e} key={e.id} />
              ))}
            </tbody>
          </table>
        </div> */}
			</div>
		</>
	);
}

const useContributors = () => {
	const [contributors, setContributors] = useState([]);

	const getContributors = async () => {
		try {
			const contributorList = await dispatch.app.getContributors();
			setContributors(contributorList);
		} catch (e) {
			console.log(e);
		}
	};

	useEffect(() => {
		getContributors();
	}, []);

	return { contributors };
};
