import React from "react";

export default function Column({ className, children, ...props }) {
	return (
		<div>
			<p
				className={`text-sm font-medium text-gray-900 text-left relative ${className}`}
				{...props}
			>
				{children}
			</p>
		</div>
	);
}
