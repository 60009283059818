import * as Yup from 'yup';
import { dispatch } from "../../redux/store";

export function useAddMarchant() {

    const initialValues = {
        firstName: "",
        lastName: "",
        phone: "",
        bvn: "58665446792",
        address: "",
        email: "",
        password: "AjotNewUser20204$$",
        userType: "MERCHANT",
        gender: ""
    };

    const validationSchema = Yup.object().shape({
        firstName: Yup.string().required('First Name Name is required'),
        lastName: Yup.string().required('Last Name is required'),
        phone: Yup.string().required('Phone Number is required'),
        address: Yup.string().required('Permanent address is required'),
        gender: Yup.string().required('Gender is required'),
        password: Yup.string().required('Password is required'),
        userType: Yup.string().required('UserType is required'),
        email: Yup.string().email('Invalid email address').required('Email is required'),
    });

    async function createAccount(values, { resetForm }) {
        try {
            const data = await dispatch.auth.adminCreateUser(values);
            resetForm()
            alert("User successfully created by Admin");
            return data
        } catch (err) {
            alert("An error occurred. Please try again later");
            console.log(err.message)
        }
    }


    return {
        initialValues,
        validationSchema,
        createAccount
    }
}
