import { useState } from "react";
import { AiFillCaretDown } from "react-icons/ai";

export default function Profile() {
  return (
    <div className="block p-8 rounded-sm bg-white max-w-6xl mx-20">
      <form>
        <div className="grid grid-cols-2 gap-4 place-content-center">
          <div className="form-group mb-6">
            <label htmlFor="firstName" className="text-neutral-label text-sm">
              First Name
            </label>
            <input
              type="text"
              className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-primary-100 focus:outline-none"
              id="first_name"
              name="firstName"
              aria-describedby="emailHelp123"
            />
          </div>
          <div className="form-group mb-6">
            <label htmlFor="lastName" className="text-neutral-label text-sm">
              Last Name
            </label>
            <input
              type="text"
              className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-primary-100 focus:outline-none"
              id="last_name"
              name="lastName"
              aria-describedby="emailHelp124"
            />
          </div>
        </div>
        <div className="flex flex-col">
          <label className="text-neutral-label text-sm">Phone Number</label>
          <div className="grid grid-flow-col grid-cols-12 gap-x-4">
            <div className="dropdown h-fit relative col-span-3 bg-[#FCFCFC] rounded-sm">
              <button
                className="dropdown_button !px-2"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false">
                <p className="font-normal text-[#091E42]">+234</p>
                <AiFillCaretDown size={16} className="ml-4" />
              </button>
              <ul
                aria-labelledby="dropdownMenuButton1"
                className={`dropdown-menu dropdown_list`}>
                <li>
                  <button
                  //   onClick={() => category !== "All" && setCategory("All")}
                  >
                    +91
                  </button>
                </li>
                <li>
                  <button
                  //   onClick={() => category !== "Active" && setCategory("Active") }
                  >
                    +231
                  </button>
                </li>
                <li>
                  <button
                  //   onClick={() => category !== "Inactive" && setCategory("InActive") }
                  >
                    +1
                  </button>
                </li>
              </ul>
            </div>
            <div className="form-group mb-6 col-span-9">
              <input
                type="number"
                className="!appearance-none form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-primary-100 focus:outline-none"
                id="phone_number"
                name="phoneNumber"
                aria-describedby="emailHelp124"
              />
            </div>
          </div>
        </div>
        <div className="form-group mb-6">
          <label htmlFor="email" className="text-neutral-label text-sm">
            Email Address
          </label>
          <input
            type="email"
            className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-primary-100 focus:outline-none"
            id="email"
            name="email"
            aria-describedby="emailHelp125"
          />
        </div>
        <div className="form-group mb-6">
          <label htmlFor="currPassword" className="text-neutral-label text-sm">
            Current Password
          </label>
          <input
            type="text"
            className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-primary-100 focus:outline-none"
            id="curr_Password"
            name="currPassword"
            aria-describedby="emailHelp123"
          />
        </div>
        <div className="form-group mb-6">
          <label htmlFor="newPassword" className="text-neutral-label text-sm">
            New Password
          </label>
          <input
            type="text"
            className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-primary-100 focus:outline-none"
            id="new_Password"
            name="newPassword"
            aria-describedby="emailHelp123"
          />
        </div>
        <div className="form-group mb-6">
          <label
            htmlFor="confirmPassword"
            className="text-neutral-label text-sm">
            Confirm Password
          </label>
          <input
            type="text"
            className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-primary-100 focus:outline-none"
            id="confirm_Password"
            name="confirmPassword"
            aria-describedby="emailHelp123"
          />
        </div>

        <button
          type="submit"
          className="button_solid bg-primary-100 !py-2 w-fit px-16">
          Sign up
        </button>
      </form>
    </div>
  );
}
