import { reducerActions } from "../reducer";
import { authAPI } from "../../services/apis/auth";
import { deleteToken, getToken, saveToken } from "../../utils/helpers";

const initState = null;

export const auth = {
	state: initState,
	reducers: reducerActions,
	effects: (dispatch) => ({
		async logout(payload, rootState) {
			dispatch.auth.clear();
			deleteToken();
		},
		async login(payload) {
			dispatch.error.clear();

			const requestPayload = {
				id: payload.email,
				password: payload.password,
			};

			try {
				const { data } = await authAPI.login(requestPayload);
				saveToken(data.tokens.access);
				dispatch.auth.setState(data);
			} catch (e) {
				console.log(e);
				dispatch.error.setError({ message: e.message });
			}
		},
		async signup({ payload, cb }, state) {
			dispatch.error.clear();
			try {
				let name = payload.fullname.split(" ");
				payload.firstName = name[0];
				payload.lastName = name?.[1] || "";
				payload.phone = payload.number;
				const data = await authAPI.signup(payload);
				cb();
			} catch (e) {
				console.log(e);
				dispatch.error.setError({ message: e.message });
			}
		},
		async adminCreateUser(payload) {
			dispatch.error.clear();
			try {
				const token = await getToken();
				if (!token) await Promise.reject('User session timed out');

				const data = await authAPI.adminCreateUser(payload, token);
				return data
			} catch (e) {
				console.log(e);
				dispatch.error.setError({ message: e.message });
			}
		},
		async changePassword({ payload, cb }, state) {
			try {
				const changePasswordPayload = {
					id: payload.id,
					password: payload.password,
					code: payload.code,
				};

				console.log(changePasswordPayload);

				await authAPI.changePassword(changePasswordPayload);
				cb();
			} catch (e) {
				console.log(e.message);
				dispatch.error.setError({ message: e.message });
			}
		},
	}),
};
