import {
  BsChevronLeft,
  BsChevronRight,
  BsDot,
  BsPlus,
  BsSearch,
} from "react-icons/bs";
import { AiFillCaretDown } from "react-icons/ai";
import { HiDotsCircleHorizontal } from "react-icons/hi";
import { MdCheckCircle } from "react-icons/md";
import { IoMdPeople } from "react-icons/io";

import Header from "../../../sharedComponents/Header";
import { transactionData } from "./transactionData";
import TransactionRow from "./transactionRow";

export default function Transactions() {
  return (
    <>
      <Header desc="Something about transactions" page="Transactions" />
      <div className="flex flex-col items-start flex-1 px-6 py-4 bg-neutral-60">
        <div className="flex items-center flex-row justify-between w-full">
          <h3 className="text-lg text-[#091E42] font-bold">Transactions</h3>
          <div className="flex flex-row space-x-4 justify-end items-center">
            <h4 className="text-[#030D25] font-bold text-sm">Summary:</h4>
            <div className="flex flex-row px-4 py-3 bg-white space-x-4">
              <IoMdPeople
                color="#2E9CDB"
                size={20}
                className="flex self-center"
              />
              <div>
                <p className="text-center text-[#7D8498] text-sm">Total</p>
                <p className="text-[#030D25] text-sm">50 Merchants</p>
              </div>
            </div>
            <div className="flex flex-row px-4 py-3 bg-white space-x-4">
              <MdCheckCircle
                color="#66B286"
                size={20}
                className="flex self-center"
              />
              <div>
                <p className="text-center text-[#7D8498] text-sm">Paid</p>
                <p className="text-[#030D25] text-sm">20 Merchants</p>
              </div>
            </div>
            <div className="flex flex-row px-4 py-3 bg-white space-x-4">
              <HiDotsCircleHorizontal
                color="#FFD56A"
                size={20}
                className="flex self-center"
              />
              <div>
                <p className="text-center text-[#7D8498] text-sm">Pending</p>
                <p className="text-[#030D25] text-sm">11 Merchants</p>
              </div>
            </div>
          </div>
        </div>

        <div className="overflow-x-auto w-full h-full min-w-[50rem]">
          <table className="whitespace-nowrap min-w-full">
            <thead className="border-b ">
              <tr>
                <th
                  scope="col"
                  className="text-sm font-bold text-gray-900 w-10 py-4 flex items-center flex-row text-left">
                  <input
                    className={`form-check-input checkbox `}
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                  />
                </th>
                <th
                  scope="col"
                  className="text-sm font-bold text-gray-900 px-4 w-40 py-4 text-left">
                  Merchant ID
                </th>
                <th
                  scope="col"
                  className="text-sm font-bold text-gray-900 px-4 w-40 py-4 text-left">
                  Merchant Name
                </th>
                <th
                  scope="col"
                  className="text-sm font-bold text-gray-900 px-4 w-16 py-4 text-left">
                  Merchant Type
                </th>
                <th
                  scope="col"
                  className="text-sm font-bold text-gray-900 px-4 w-16 py-4 text-left">
                  Amount
                </th>
                <th
                  scope="col"
                  className="text-sm font-bold text-gray-900 px-4 w-16 py-4 text-left">
                  Date
                </th>
                <th
                  scope="col"
                  className="text-sm font-bold text-gray-900 px-4 w-12 py-4 text-left">
                  Status
                </th>
                <th
                  scope="col"
                  className="text-sm font-bold text-gray-900 px-4 w-10 py-4 text-center">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {transactionData.map((e) => (
                <TransactionRow {...e} key={e.id} />
              ))}
            </tbody>
          </table>
        </div>
        <div className="flex flex-row justify-between items-center w-full mt-4">
          <p className="text-[#091E42] text-xs">
            Showing 1-10 <span className="text-primary-200">results </span>of 30
            records
          </p>
          <div className="flex flex-row items-center justify-end space-x-3">
            <button
              disabled
              className="disabled:opacity-50 appearance-none p-2 border-2 border-[#DBDCE0] rounded-lg">
              <BsChevronLeft size={14} />
            </button>
            <span className={`text-xs cursor-pointer active_index`}>1</span>
            <span className="text-xs cursor-pointer ">2</span>
            <span className="text-xs cursor-pointer ">3</span>
            <span className="flex flex-row cursor-pointer">
              <span className="text-xs">
                <BsDot size={32} color="#B7BCC2" />
              </span>
              <span className="text-xs -mx-4">
                <BsDot size={32} color="#B7BCC2" />
              </span>
              <span className="text-xs ">
                <BsDot size={32} color="#B7BCC2" />
              </span>
            </span>
            <button className="appearance-none p-2 border-2 border-[#DBDCE0] rounded-lg">
              <BsChevronRight size={14} />
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
