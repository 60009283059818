import { BsThreeDotsVertical } from "react-icons/bs";
import { DateFormat } from "../../utils/helpers";
import Dropdown from "../../sharedComponents/Dropdown";
import Column from "../../sharedComponents/Column";

const dropDownOptions = ({ id, navigate }) => [
	{
		label: "View Profile",
		onClick: () => navigate(id),
	},
];

export const CustomerColumn = () => [
	{
		title: "Customer Name",
		index: "name",
		render: (_, data) => <Column>{data?.firstName} {data?.lastName}</Column>,
	},
	{
		title: "Phone Number",
		index: "phone",
		render: (_, data) => <Column>{data?.phoneNumber}</Column>,
	},
	{
		title: "Date Created",
		index: "dateCreated",

		render: (_, data) => <Column>{DateFormat(data?.createdAt)}</Column>,
	},
	// {
	// 	title: "Action",
	// 	index: "action",
	// 	render: (_, data) => (
	// 		<Column>
	// 			<Dropdown buttonClass="!mx-0" options={dropDownOptions({ id: data.id, navigate })}>
	// 				<BsThreeDotsVertical size={16} color="#B7BCC2" />
	// 			</Dropdown>
	// 		</Column>
	// 	),
	// },
];
