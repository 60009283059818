import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { dispatch } from "../../../redux/store";
import { message } from "antd";

export default function usePayout() {
	const [payouts, setPayouts] = useState([]);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [reason, setReason] = useState("")

	const [requestID, setRequestID] = useState("")

	const openModal = () => {
		setIsModalOpen(true);
	};

	const closeModal = () => {
		setIsModalOpen(false);
	};

	const handle = (id) => {
		setRequestID(id)
		openModal()
	}

	useEffect(() => {
		async function getPayoutRequests() {
			const payoutRequests = await dispatch.app.getMerchantRequests();

			payoutRequests && setPayouts(payoutRequests);
		}

		getPayoutRequests();
	}, []);

	function changeRequestStatus(id, status) {
		const response = dispatch.app.changeWithdrawalStatus({ id, status, reason });
		if (!response) {
			message.error("Error occurred");
			return;
		}
		setPayouts((prevPayouts) => {
			return prevPayouts.map((payout) => {
				if (payout.id === id) return { ...payout, requestStatus: status };
				return payout;
			});
		})
		message.success("Status updated");
	}

	return {
		payouts,
		changeRequestStatus,
		isModalOpen,
		closeModal,
		reason,
		setReason,
		handle,
		requestID
	};
}
