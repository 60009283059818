import { NavLink, Link, useNavigate } from "react-router-dom";
import Landing from "../../assets/images/landing.png";
import { ImLocation2 } from "react-icons/im";

export default function Home() {
	const navigate = useNavigate();

	return (
		<div className="w-full h-screen bg-white">
			<nav className="px-[8%] py-6 flex flex-row justify-end items-center">
				<Link className="mr-auto font-bold text-4xl text-primary-100" to="/landing">
					Ajot
				</Link>
				<NavLink
					className={({ isActive }) =>
						!isActive
							? "mr-8 text-[#18191F] text-sm hover:text-blue-600 transition-colors duration-300"
							: "mr-8 text-primary-100 text-sm"
					}
					to="/home">
					Home
				</NavLink>
				<NavLink
					className={({ isActive }) =>
						!isActive
							? "mr-8 text-[#18191F] text-sm hover:text-blue-600 transition-colors duration-300"
							: "mr-8 text-primary-100 text-sm"
					}
					to="/about_us">
					About us
				</NavLink>
				<NavLink
					className={({ isActive }) =>
						!isActive
							? "mr-8 text-[#18191F] text-sm hover:text-blue-600  transition-colors duration-300"
							: "mr-8 text-primary-100 text-sm"
					}
					to="/how_it_works">
					How it works
				</NavLink>
				<NavLink
					className={({ isActive }) =>
						!isActive
							? "mr-8 text-[#18191F] text-sm hover:text-blue-600  transition-colors duration-300"
							: "mr-8 text-primary-100 text-sm"
					}
					to="/faq">
					FAQ
				</NavLink>
				<Link
					className="px-6 py-2 border-primary-100 border-[.5px] rounded-md bg-white text-primary-100 mr-4"
					to="/login">
					Login
				</Link>
				{/* <Link
          className="px-6 py-2 bg-primary-100 box-border text-white border-2 mr-4 rounded-md"
          to="/signup">
          Sign up
        </Link> */}
			</nav>
			<div className="flex flex-row items-center pl-[8%]">
				<div className="flex flex-col w-2/5">
					<h2 className="text-5xl text-[#18191F] font-extrabold leading-[60px] mb-4">
						A Penny a Day, Brings the Target Closer.
					</h2>
					<p className="text-[#18191F] leading-8">
						We are committed to funding your dreams because we believe in your ability
						to change the world through your value propositions.
					</p>
					{/* <button className="button_solid !w-max px-8" onClick={() => navigate("login")}>
						Get Started for Merchants
					</button> */}
				</div>
				<div className="w-3/5 flex items-center">
					<img src={Landing} className="h-full w-full" alt="" />
				</div>
			</div>
			{/* <div className="flex flex-1 justify-center items-center space-x-8 mt-8">
				<div className="relative text-[#091E42] w-[300px]">
					<ImLocation2 className="absolute top-[15px] left-4" color="#2D9CDB" />
					<input
						type="search"
						name="q"
						className="py-3 text-sm w-full text-[#B3BAC5] bg-white border-2 border-primary-100 rounded-md pl-11 pr-4 focus:outline-none focus:bg-white focus:text-gray-900"
						placeholder="Enter Location"
						autoComplete="off"
					/>
				</div>
				<button className="button_solid !w-max !px-8 !py-3 !mt-0 border-0 border-primary-100">
					Find Merchant
				</button>
			</div> */}
		</div>
	);
}
