import { Outlet } from "react-router-dom";

import Link from "./Link";
import styles from "./sidebar.module.css";

export default function SideBar() {
	return (
		<div className={styles.container}>
			<div className={styles.containerLeft}>
				<h3 className={styles.appName}>Ajot</h3>
				<Link to="Dashboard" />
				{/* <Link to="Contributor" name="Contributors" /> */}
				{/* <Link to="Transactions" /> */}
				<Link to="Merchant" name="Merchants" />
				<Link to="Payouts" />
				<Link to="Customer" name="Customers" />
				<Link to="Closebooks" name="Closed Books" />
				<Link to="waitlists" name="Waitlists" />
				<Link to="addmerchant" name="Add User" />
				<Link to="manualclosebooks" name="Manual Closed Books" />
				{/* <Link to="Settings" /> */}
				{/* <Link to="Contact-us" /> */}
				{/* <Link to="Terms-of-use" /> */}
			</div>
			<div className={styles.containerRight}>
				<Outlet />
			</div>
		</div>
	);
}
