import { AbstractClass } from "./AbstractClass";

export class Contribution extends AbstractClass {
	constructor(props) {
		super(props);
		this.addedBy = props?.addedBy;
		this.status = props?.status;
		this.contributionLimit = props?.contributionLimit;
		this.maturityDate = props?.maturityDate;
		this.contributionType = props?.contributionType;
		this.history = props?.history || [];
		this.startDate = props?.startDate;
		this.amount = props?.amount;
		this.customer = props?.customer;
		this.targetDuration = props?.targetDuration;
	}
}
