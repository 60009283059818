export const transactionData = [
  {
    name: "Sean Woodward",
    id: "FVH36YWO5EA",
    bank: "Jeju",
    amount: "32450",
    date: "20/4/2021",
    status: false,
    duration: "3",
  },
  {
    name: "Quinn Crosby",
    id: "BQI84VTU7MS",
    bank: "North Gyeongsang",
    amount: "95905",
    date: "14/10/2021",
    status: true,
    duration: "2",
  },
  {
    name: "Tiger Kirk",
    id: "UQS72EME6QK",
    bank: "North Chungcheong",
    amount: "58485",
    date: "10/3/2023",
    status: true,
    duration: "2",
  },
  {
    name: "Aileen Maldonado",
    id: "ABC57PUE9JR",
    bank: "Jambi",
    amount: "22580",
    date: "26/3/2021",
    status: true,
    duration: "5",
  },
  {
    name: "Connor Stanley",
    id: "RQN87GGS2NM",
    bank: "Dōngběi",
    amount: "72216",
    date: "15/6/2022",
    status: true,
    duration: "3",
  },
  {
    name: "Uriah Frazier",
    id: "GEX22CHZ4DE",
    bank: "Dōngběi",
    amount: "26435",
    date: "3/6/2021",
    status: true,
    duration: "3",
  },
  {
    name: "Guinevere Miranda",
    id: "CTK61VTL7PS",
    bank: "Melilla",
    amount: "50339",
    date: "19/12/2021",
    status: false,
    duration: "3",
  },
  {
    name: "Portia Franco",
    id: "QAD86HMD3KX",
    bank: "KwaZulu-Natal",
    amount: "20389",
    date: "22/9/2022",
    status: true,
    duration: "4",
  },
  {
    name: "Leonard Cochran",
    id: "MRE70FWW0EX",
    bank: "Munster",
    amount: "38258",
    date: "1/3/2023",
    status: false,
    duration: "4",
  },
  {
    name: "Nina Warner",
    id: "TCE71MEB8KC",
    bank: "Ninh Thuận",
    amount: "33797",
    date: "27/2/2022",
    status: false,
    duration: "2",
  },
];
