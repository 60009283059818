import handleAPI from "../index";
import { AddAuthorization } from "./auth";

export const appAPI = {
	createCustomer: (data, tokens) => 90 *
		handleAPI.post("customer/create", data, AddAuthorization(tokens)),
	getCustomers: () => handleAPI.get("customer", null, AddAuthorization()),
	getWaitLists: () => handleAPI.get("auth/all-campaign", null, null),
	fetchCustomerByID: (id) => handleAPI.get(`customer/${id}`, null, AddAuthorization()),
	createPlan: (data, tokens) =>
		handleAPI.post("contribution/add-plan", data, AddAuthorization(tokens)),
	createContribution: (data, tokens) =>
		handleAPI.post("contribution/create", data, AddAuthorization(tokens)),
	getContributors: () => handleAPI.get("contribution/history", null, AddAuthorization()),

	fetchMerchantContributors: (id) => handleAPI.get(`contribution/merchant/${id}`, null, AddAuthorization()),

	//* admin to get a contributor total conr=tribution
	fetchTotalContribtion: (data) => handleAPI.get("contribution/total-contributions", data, AddAuthorization()),
	fetchContributionHistory: (id) => handleAPI.get(`contribution/history/${id}`, null, AddAuthorization()),
	fetchCustomerContributionHistory: (id) => handleAPI.get(`contribution/customer-history/${id}`, null, AddAuthorization()),

	getClosedBooks: () => handleAPI.get("payment/close-book", null, AddAuthorization()),


	/**
	 * *Get totaltransaction records by admin
	 */

	fetchTotalDeposit: (id) => handleAPI.get(`payment/merchant-payments/${id}/deposit`, null, AddAuthorization()),
	fetchTotalWithdrawal: (id) => handleAPI.get(`payment/merchant-payments/${id}/withdrawal`, null, AddAuthorization()),
	fetchDashboardUsers: () => handleAPI.get('dashboard/users', null, AddAuthorization()),
	fetchDashboardContribution: () => handleAPI.get('dashboard/contributions', null, AddAuthorization()),
	fetchDashboardAmount: () => handleAPI.get('dashboard/amount', null, AddAuthorization()),
	fetchLeaderBoard: (data, tokens) =>
		handleAPI.get("contribution/leaderboard", data, AddAuthorization(tokens)),
	fetchMerchants: () => handleAPI.get("merchant", null, AddAuthorization()),
	fetchMerchantByID: (id) => handleAPI.get(`merchant/${id}`, null, AddAuthorization()),
	getContributions: (data, tokens) =>
		handleAPI.get("contribution", data, AddAuthorization(tokens)),
	getContributionsByDate: (data, tokens) =>
		handleAPI.get("contribution/today", data, AddAuthorization(tokens)),
	getHistoryByDate: (data, tokens) => handleAPI.get("history", data, AddAuthorization(tokens)),
	getRevenue: (tokens) => handleAPI.get("contribution/sum", null, AddAuthorization(tokens)),
	getContributionsWithHistory: (tokens) =>
		handleAPI.get("customer", { history: "TRUE" }, AddAuthorization(tokens)),
	markContribution: (data, tokens) =>
		handleAPI.post("contribution/mark", data, AddAuthorization(tokens)),
	initiateBVN: (data, tokens) =>
		handleAPI.post("payment/initialize-bvn", data, AddAuthorization(tokens)),
	verifyBVN: (data, tokens) =>
		handleAPI.post("payment/verify-bvn", data, AddAuthorization(tokens)),
	getMerchantRequests: (tokens) =>
		handleAPI.get("withdrawal/merchant-request", null, AddAuthorization(tokens)),
	changeWithdrawalStatus: (data, tokens) =>
		handleAPI.patch(`withdrawal/merchant-request/${data.id}`, data, AddAuthorization(tokens)),

	getAllUsers: () => handleAPI.get("auth/all", null, AddAuthorization()),
	getManualCloseBooks: (tokens) => handleAPI.get("payment/get-manual-closed-books", null, AddAuthorization(tokens)),
	confirmManualPayment: (data, tokens) =>
		handleAPI.post("payment/confirm-manual-payment-by-admin", data, AddAuthorization(tokens)),
};
