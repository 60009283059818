import React from "react";
import Header from "../../../sharedComponents/Header/header";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { FaAngleDoubleRight } from "react-icons/fa";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { dispatch } from "../../../redux/store";
import { ImLocation } from "react-icons/im";
import { BsTelephoneFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import LoaderButton from "../../../sharedComponents/Loader/LoaderButton";
import CustomTable from "../../../sharedComponents/CustomTable";

import { ContributorHistoryColumn } from './ContributionHistoryCol'
import Skeleton from "../../../sharedComponents/Loader/Skeleton";
import { useSelector } from "react-redux";
import { Button, Divider, message } from "antd";
import { Customer } from "../../../classes/Customer";
import { ContributionHistory } from "../../../classes/ContributionHistory";




export default function Contributor() {
    const {
        goBack,
        loading,
        customerDetails,
        total,
        // getContributionHistory,
        historyD
    } = useContributorPage();

    const { firstName, lastName, email, phoneNumber, occupation } = customerDetails;

    return (
        <div className="flex flex-1 flex-col">
            <Header page="Merchant" desc="Manage your merchants all in one place" />
            <div className="grid grid-cols-4 w-full bg-[#F5F5F5]">
                <div className="flex flex-col col-span-3 bg-white">
                    <div className="flex flex-row">
                        <div className="flex flex-row space-x-4 items-center h-auto p-6">
                            <span onClick={goBack} className="flex space-x-2 items-center">
                                <MdOutlineKeyboardBackspace size={24} />
                                <h3 className="font-semibold text-neutral-900 text-xl">Contributor</h3>
                            </span>
                            <FaAngleDoubleRight size={24} />
                            <Skeleton loading={loading}>
                                <h3 className="font-semibold text-slate-500 text-xl">{`${firstName} ${lastName}`}</h3>
                            </Skeleton>
                        </div>
                    </div>


                    {/* contribution data section */}
                    <div className="col-span-1 p-5" >
                        <div className=" rounded-md flex justify-center flex-col flex-1 p-8 bg-white">
                            <h4 className="font-semibold text-center">Contribution Details</h4>
                            <Skeleton loading={loading}>
                                {/* <h3 className="font-semibold text-slate-500 text-xl pt-10">{`Total Amount Contributed : ${total ? total : "No record found"}`}</h3> */}
                                <span className="flex space-x-2 items-center">
                                    <h2 className="text-neutral-900 text-lg">Total Contribution made: </h2>
                                    <h3 className="font-semibold text-slate-500 text-lg">{total ? "₦" + total : "No contribution made yet"}</h3>
                                </span>
                            </Skeleton>
                        </div>
                    </div>
                    {/* contribution table */}
                    {
                        historyD && (
                            <CustomTable
                                columns={ContributorHistoryColumn()}
                                data={historyD}
                            />
                        )
                    }
                </div>

                <div className="col-span-1 p-4">
                    <div className=" rounded-md flex justify-center flex-col flex-1 p-4 bg-white">
                        <h4 className="font-semibold text-center">{`${firstName}`}</h4>
                        <div className="flex flex-col justify-content gap-8 mt-8">
                            <div className="flex justify-between">
                                <ImLocation />
                                <Skeleton loading={loading}>
                                    <span className="text-sm">{email}</span>
                                </Skeleton>
                            </div>
                            <div className="flex justify-between">
                                <BsTelephoneFill />
                                <Skeleton loading={loading}>
                                    <span className="text-sm">{phoneNumber}</span>
                                </Skeleton>
                            </div>
                            <div className="flex justify-between">
                                <MdEmail />
                                <Skeleton loading={loading}>
                                    <span className="text-sm">{occupation}</span>
                                </Skeleton>
                            </div>
                        </div>
                        {/* <Button
                            className={`mt-[100%] flex self-center px-4 pb-2`}
                            // isLoading={false}
                            onClick={getContributionHistory}
                        >
                            Load Contribution History
                        </Button> */}
                    </div>
                </div>
            </div>
        </div>
    );
}

const useContributorPage = () => {
    const navigate = useNavigate();
    const { id } = useParams("id");

    const loading = useSelector((state) => state.loading.effects.app.getCustomerByID);
    const [customerDetails, setCustomerDetails] = useState(new Customer());
    const [total, setTotal] = useState("");
    const [historyD, setHistoryD] = useState([]);

    console.log("cus", customerDetails)

    const getContributionDetails = async () => {
        const customer = await dispatch.app.getCustomerByID(id);
        customer && setCustomerDetails(customer);
        console.log("customer", customer)
        customer && customer.contributions.map(async (res) => {
            const amount = await dispatch.app.getTotalContribtion({
                id: res.id,
                customerId: customer.id
            });
            const history = await dispatch.app.getContributionHistory(res.id);
            amount && setTotal(amount)
            historyD && setHistoryD(history);
        })
    };

    useEffect(() => {
        getContributionDetails()
    }, [id]);

    const goBack = () => navigate(-1);

    return {
        goBack,
        loading,
        customerDetails,
        total,
        historyD
    };
}; 
