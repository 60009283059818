import { BsThreeDotsVertical } from "react-icons/bs";

export default function TransactionRow({ id, name, amount, date, status }) {
  return (
    <tr className="border-b">
      <td className="text-sm font-medium text-gray-900 px-4 pl-0 w-10 py-4 text-left">
        <input
          className={`form-check-input checkbox `}
          type="checkbox"
          value=""
          id="flexCheckDefault"
        />
      </td>
      <td className="text-sm font-medium text-gray-900 px-4 w-40 py-4 text-left">
        {id}
      </td>
      <td className="text-sm font-medium text-gray-900 px-4 w-40 py-4 text-left">
        {name}
      </td>
      <td className="text-sm font-medium text-gray-900 px-4 w-40 py-4 text-left">
        {status ? "Myself" : "POS"}
      </td>

      <td className="text-sm font-medium text-gray-900 px-4 w-16 py-4 text-left">
        {"₦ " + amount}
      </td>
      <td className="text-sm font-medium text-gray-900 px-4 w-16 py-4 text-left">
        {date}
      </td>
      <td className="text-sm font-medium text-gray-900 px-4 w-12 py-4 text-left">
        {status ? (
          <span className="rounded-md bg-[#FAF5E5] text-[#D8AA1C] p-2">
            Pending
          </span>
        ) : (
          <span className="rounded-md bg-[#EAFDEB] text-[#66B286] p-2">
            Accepted
          </span>
        )}
      </td>
      <td className="text-sm font-medium text-gray-900 px-4 w-10 py-4 text-left relative dropdown">
        <button
          className="appearance-none w-fit mx-auto px-4 flex justify-center"
          type="button"
          id={id}
          data-bs-toggle="dropdown"
          aria-expanded="false">
          <BsThreeDotsVertical size={16} color="#B7BCC2" />
        </button>
        <ul
          aria-labelledby="dropdownMenuButton1"
          className={`dropdown-menu dropdown_list`}>
          <li>
            <button>View Customer</button>
          </li>
        </ul>
      </td>
    </tr>
  );
}
