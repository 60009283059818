import { reducerActions } from "../reducer";
import { appAPI } from "../../services/apis/app";
import { getToken } from "../../utils/helpers";
import { Contributor } from "../../classes/Contributor";
import { Contribution } from "../../classes/Contribution";
import { Merchant } from "../../classes/Merchant";
import { Customer } from "../../classes/Customer";
import { ContributionHistory } from "../../classes/ContributionHistory";

const initState = null;

export const app = {
	state: initState,
	reducers: reducerActions,
	effects: (dispatch) => ({
		async getCustomers() {
			dispatch.error.clear();

			try {
				const { data } = await appAPI.getCustomers();
				return data;
			} catch (e) {
				dispatch.error.setState({ message: e.message });
			}
		},
		async getWaitLists() {
			dispatch.error.clear();

			try {
				const { data } = await appAPI.getWaitLists();
				return data;
			} catch (e) {
				dispatch.error.setState({ message: e.message });
			}
		},
		async getClosedBooks() {
			dispatch.error.clear();

			try {
				const { data } = await appAPI.getClosedBooks();
				return data;
			} catch (e) {
				dispatch.error.setState({ message: e.message });
			}
		},
		async getManualCloseBooks() {
			dispatch.error.clear();
			try {
				const { data } = await appAPI.getManualCloseBooks();
				return data;
			} catch (e) {
				dispatch.error.setState({ message: e.message });
			}
		},
		async confirmManualPayment(payload) {
			dispatch.error.clear();
			const token = getToken();
			if (!token) await Promise.reject("User session timed out");
			try {
				const { data } = await appAPI.confirmManualPayment(payload, token);
				return data;
			} catch (e) {
				dispatch.error.setState({ message: e.message });
			}
		},
		async getCustomerByID(id) {
			try {
				const { data } = await appAPI.fetchCustomerByID(id);
				return new Customer(data);
			} catch (error) {
				dispatch.error.setState({ message: error.message });
				console.log(error);
			}
		},
		async getMerchantContributors(id) {
			try {
				const { data } = await appAPI.fetchMerchantContributors(id);
				// if (!data) throw new Error("No contributors");
				const contributors = data.map((contributor) => new Contribution(contributor));

				return contributors;
			} catch (e) {
				dispatch.error.setState({ message: e.message });
				console.log(e);
			}
		},

		async getContributionHistory(id) {
			try {
				const { data, message } = await appAPI.fetchContributionHistory(id);
				if (message === "Cannot read property 'id' of null") {
					return dispatch.error.setState({ message: "Contribution Id not found" });
				}
				const contributionHistory = data?.history.map((history) => new ContributionHistory(history));
				return contributionHistory
			} catch (e) {
				dispatch.error.setState({ message: e.message });
				console.log(e);
			}
		},

		async getContributionCustomerHistory(id) {
			try {
				const { data, message } = await appAPI.fetchCustomerContributionHistory(id);

				if (message === "Cannot read property 'id' of null") {
					return dispatch.error.setState({ message: "Customer Id not found" });
				}
				if (data.length === 0) return dispatch.error.setState({ message: "Customer history not found" });
				const contributionHistory = data[0]?.history.map((res) => res);
				if (contributionHistory) {
					return contributionHistory
				} else {
					return dispatch.error.setState({ message: "Customer history not found (contributor)" });
				}

			} catch (e) {
				dispatch.error.setState({ message: e.message });
				console.log(e);
			}
		},

		async getTotalDeposit(id) {
			try {
				const { data } = await appAPI.fetchTotalDeposit(id);
				return data;
			} catch (e) {
				dispatch.error.setState({ message: e.message });
				console.log(e);
			}
		},

		async getTotalWithrawal(id) {
			try {
				const { data } = await appAPI.fetchTotalWithdrawal(id);
				return data;
			} catch (e) {
				dispatch.error.setState({ message: e.message });
				console.log(e);
			}
		},

		async getTotalContribtion(payload) {
			try {
				const { data } = await appAPI.fetchTotalContribtion(payload);
				return data
			} catch (error) {
				console.log(error);
			}
		},

		async getDashboardUsers() {
			try {
				const { data } = await appAPI.fetchDashboardUsers();
				return data
			} catch (e) {
				dispatch.error.setState({ message: e.message });
				console.log(e);
			}
		},
		async getDashboardContributions() {
			try {
				const { data } = await appAPI.fetchDashboardContribution();
				return data
			} catch (e) {
				dispatch.error.setState({ message: e.message });
				console.log(e);
			}
		},
		async getDashboardAmount() {
			try {
				const { data } = await appAPI.fetchDashboardAmount();
				return data
			} catch (e) {
				dispatch.error.setState({ message: e.message });
				console.log(e);
			}
		},

		async addContributor({ payload, cb }, state) {
			dispatch.error.clear();

			try {
				const token = getToken();
				if (!token) await Promise.reject("User session timed out");

				const planPayload = {
					limit: payload.duration.split(" ")[0],
					duration: payload.duration.split(" ")[1],
					planType: payload.plan,
				};

				const planResponse = await appAPI.createPlan(planPayload, token);

				const customerPayload = {
					fullName: payload.fullname,
					emailAddress: payload.email,
					occupation: payload.occupation,
					phoneNumber: payload.phoneNumber,
					nextOfKin: {
						fullName: "",
						phoneNumber: "",
					},
				};

				console.log(customerPayload);

				const customerResponse = await appAPI.createCustomer(customerPayload, token);

				const contributionPayload = {
					status: "ACTIVE",
					amount: payload.amount,
					customer: customerResponse.data.id,
					planId: planResponse.data.id,
				};

				const contributionResponse = await appAPI.createContribution(contributionPayload, token);

				console.log(contributionResponse);
				cb();
			} catch (e) {
				console.log(e.message);
				dispatch.error.setError({ message: e.message });
			}
		},
		async getContributors() {
			try {
				const { data } = await appAPI.getContributors();
				if (!data) throw new Error("No contributors");

				const contributors = data.map((contributor) => new Contributor(contributor));

				console.log(contributors);
				return contributors;
			} catch (e) {
				console.log({ message: e.message });
			}
		},
		async getContributions(payload) {
			try {
				const token = getToken();
				if (!token) await Promise.reject("User session timed out");

				const duration = payload === "All" ? {} : { duration: payload };

				const contributions = await appAPI.getContributions(duration, token);

				return contributions;
			} catch (e) {
				console.log(e.message);
				dispatch.error.setError({ message: e.message });
			}
		},
		async getLeaderBoard(payload) {
			try {
				const token = getToken();
				if (!token) await Promise.reject("User session timed out");

				const period = { period: payload };

				const leaderBoard = await appAPI.fetchLeaderBoard(period, token);

				return leaderBoard;
			} catch (e) {
				console.log(e.message);
				dispatch.error.setError({ message: e.message });
			}
		},
		async getMerchants() {
			try {
				const { data } = await appAPI.fetchMerchants();

				const result = data.filter((merchant) => merchant.account).map((merchant) => new Merchant(merchant));
				return result;
			} catch (e) {
				dispatch.error.setState({ message: e.message });
				console.log(e);
			}
		},
		async getMerchantByID(id) {
			try {
				const { data } = await appAPI.fetchMerchantByID(id);
				return new Merchant(data);
			} catch (error) {
				console.log(error);
			}
		},
		async getCustomerRevenue() {
			try {
				const token = getToken();
				if (!token) await Promise.reject("User session timed out");

				const revenue = await appAPI.getRevenue(token);
				console.log(revenue);

				return revenue;
			} catch (e) {
				console.log(e.message);
				dispatch.error.setError({ message: e.message });
			}
		},
		async getContributionsByDate(payload) {
			try {
				const token = getToken();
				if (!token) await Promise.reject("User session timed out");

				const contributions = await appAPI.getContributionsByDate(payload, token);

				return contributions;
			} catch (e) {
				console.log(e.message);
				dispatch.error.setError({ message: e.message });
			}
		},
		async getHistoryByDate(payload) {
			try {
				const token = getToken();
				if (!token) await Promise.reject("User session timed out");

				const history = await appAPI.getHistoryByDate(payload, token);

				return history;
			} catch (e) {
				console.log(e.message);
				dispatch.error.setError({ message: e.message });
			}
		},
		async getMarkingList() {
			try {
				const token = getToken();
				if (!token) await Promise.reject("User session timed out");

				const markingData = await appAPI.getContributionsWithHistory(token);

				return markingData;
			} catch (e) {
				console.log(e.message);
				dispatch.error.setError({ message: e.message });
			}
		},
		async markContribution(payload, state) {
			try {
				const token = getToken();
				if (!token) Promise.reject("User session timed out");

				await appAPI.markContribution(payload, token);
			} catch (e) {
				console.log(e.message);
				dispatch.error.setError({ message: e.message });
			}
		},
		async getMerchantRequests() {
			try {
				const token = getToken();
				if (!token) Promise.reject("User session timed out");

				const response = await appAPI.getMerchantRequests(token);
				return response.data;
			} catch (e) {
				console.log(e.message);
				dispatch.error.setError({ message: e.message });
			}
		},
		async changeWithdrawalStatus(payload) {
			try {
				const token = getToken();
				if (!token) Promise.reject("User session timed out");

				//! const payload = {id: string, status: "ACCEPTED"|"REJECTED"|"PENDING", reasons: ""}

				const response = await appAPI.changeWithdrawalStatus(payload, token);
				return response.data;
			} catch (e) {
				console.log(e.message);
				dispatch.error.setError({ message: e.message });
			}
		},
		async initiateBVN(payload, state) {
			try {
				const token = getToken();
				if (!token) Promise.reject("User session timed out");

				const response = await appAPI.initiateBVN({ user: payload }, token);
				return response;
			} catch (e) {
				console.log(e.message);
				dispatch.error.setError({ message: e.message });
			}
		},
		async verifyBvN(payload, state) {
			try {
				const token = getToken();
				if (!token) Promise.reject("User session timed out");

				const response = await appAPI.verifyBVN(payload, token);
				return response.data;
			} catch (e) {
				console.log(e.message);
				dispatch.error.setError({ message: e.message });
			}
		},

		async getAllUsers() {
			dispatch.error.clear();

			try {
				const { data } = await appAPI.getAllUsers();
				return data;
			} catch (e) {
				dispatch.error.setState({ message: e.message });
			}
		},
	}),
};
