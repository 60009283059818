import Column from "../../sharedComponents/Column";
import Dropdown from "../../sharedComponents/Dropdown";
import { BsThreeDotsVertical } from "react-icons/bs";
import { comma } from '../../sharedComponents/NairaSymbol';
import { DateFormat } from "../../utils/helpers";

const dropDownOptions = ({ id, change }) => [
    {
        label: "Approve",
        onClick: () => change({
            pid: id,
            response: "APPROVED"
        }),
    },
    {
        label: "Reject",
        buttonStyle: { color: "red" },
        onClick: () => change({
            pid: id,
            reponse: "REJECTED"
        }),
    },
];

export const PaymentColumn = () => [
    {
        title: "Merchant Name",
        index: "name",
        render: (_, data) => (
            <Column>{`${data?.merchant?.lastName} ${data?.merchant?.firstName}`}</Column>
        ),
    },
    {
        title: "Phone number",
        index: "phone",
        render: (_, data) => <Column>{data?.merchant?.phone}</Column>,
    },
    {
        title: "Email",
        index: "email",

        render: (_, data) => <Column>{data?.merchant?.email}</Column>,
    },
    { title: "Amount", index: "amount", render: (_, data) => <p>&#8358; {comma(data?.closeBookAmount)}</p> },
    { title: "Bank paid to", index: "bankName", render: (_, data) => <p>{data?.bankName}</p> },
    { title: "Account number", index: "account", render: (_, data) => <p>{data?.accountNumber}</p> },
    {
        title: "Payment Date",
        index: "recordedOn1",
        render: (_, data) => <Column>{DateFormat(data?.createdAt)}</Column>,
    },
    {
        title: "Admin Decision Date",
        index: "recordedOn",
        render: (_, data) => <Column>{DateFormat(data?.updatedAt)}</Column>,
    },
    {
        title: "Status",
        render: (_, data) => (
            <Column>{data?.paymentApprovedByAdmin}</Column>
        ),
    },
    // {
    //     title: "Change paymentStatus",
    //     index: "action",
    //     render: (_, data) => (
    //         <Column>
    //             <Dropdown buttonClass="!mx-0" options={dropDownOptions(change)}>
    //                 <BsThreeDotsVertical size={16} color="#B7BCC2" />
    //             </Dropdown>
    //         </Column>
    //     ),
    // },
];
