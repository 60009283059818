import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import { dispatch } from "../../redux/store";

import Header from "../../sharedComponents/Header/header";
import CustomTable from "../../sharedComponents/CustomTable";
import { WaitListColumn } from "./WaitListCol";

export default function Waitlist() {
    const { waitLists } = useWaitList()
    return (
        <>
            <Header page="WaitList" desc="Registered WaitList Users" />
            <div className="flex flex-col items-start flex-1 px-6 py-4 bg-neutral-60">
                <CustomTable
                    columns={WaitListColumn()}
                    data={waitLists}
                />
            </div>
        </>
    )
}

const useWaitList = () => {
    const [waitLists, setWaitLists] = useState([]);

    const fetchWaitList = async () => {
        try {
            const waitList = await dispatch.app.getWaitLists();
            setWaitLists(waitList);
        } catch (e) {
            console.log({ error: e });
        }
    };

    useEffect(() => {
        fetchWaitList();
    }, []);

    return { waitLists };
};
