import React, { useState } from "react";

/**
 * @param {DropdownProps} param0
 * @returns
 */
export default function Dropdown({ options = [], children, buttonClass }) {
	const [isOpen, setIsOpen] = useState(false);

	const handleToggle = () => {
		setIsOpen(!isOpen);
	};

	const handleItemClick = (onClick) => {
		onClick();
		setIsOpen(false);
	};

	return (
		<div className="relative">
			<button
				className={`appearance-none w-fit mx-auto px-4 flex justify-center ${buttonClass}`}
				type="button"
				onClick={handleToggle}
				aria-expanded={isOpen}
			>
				{children}
			</button>
			{isOpen && (
				<ul className="dropdown-menu" style={{ display: "block" }}>
					{options.map((el, index) => (
						<li key={index} onClick={() => handleItemClick(el.onClick)}>
							<button className={el.buttonClass} style={el.buttonStyle}>
								{el.label}
							</button>
						</li>
					))}
				</ul>
			)}
		</div>
	);
}
