import { decode } from "html-entities";

export function comma(x) {
	return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
}

export function formatCurrency(value) {
	return `${decode("&#x20A6")} ${comma(value)}`;
}

export default decode("&#x20A6");
