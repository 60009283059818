import { BsThreeDotsVertical } from "react-icons/bs";
import PaidStatusRow from "../../../sharedComponents/PaidStatusRow";
import Column from "../../../sharedComponents/Column";
import { DateFormat } from "../../../utils/helpers";
import Dropdown from "../../../sharedComponents/Dropdown";
import { BookStatusRow, EmailStatusRow } from "../../../sharedComponents/MarkedStatusRow";
import { comma } from '../../../sharedComponents/NairaSymbol';

// const dropDownOptions = ({ id }) => [
// 	// {
// 	// 	label: "View Profile",
// 	// 	onClick: () => navigate(id),
// 	// },
// ];

/**
 * @typedef {import("../../../classes/Contributor").Contributor} Contributor
 */

/**
 * @typedef {Object} ColumnType
 * @property {string} title
 * @property {string} index
 * @property {function(string, Contributor): JSX.Element} render - A function that returns a JSX element, taking in an ObjectA as a prop
 */

/**
 * @function
 * @return {ColumnType[]}
 */
export const ClosedbooksCol = () => [
    {
        title: "Merchant FullName",
        index: "name",
        render: (_, data) => <Column>{data.merchant.fullname}</Column>,
    },
    {
        title: "Merchant PhoneNumber",
        index: "phone",
        render: (_, data) => <Column>{data.merchant.phone}</Column>,
    },
    {
        title: "Merchant Email",
        index: "email",
        render: (_, data) => (
            <Column>
                {data.merchant.email}
            </Column>
        ),
    },
    {
        title: "Email Confirm Status",
        index: "emailstatus",
        render: (_, data) => (
            <Column>
                <EmailStatusRow isVerified={data.merchant.emailConfirmed} />
            </Column>
        ),
    },
    {
        title: "Merchant Address",
        index: "address",
        render: (_, data) => (
            <Column>
                {data.merchant.address}
            </Column>
        ),
    },
    {
        title: "Amount",
        index: "amount",
        render: (_, data) => <Column>&#8358;{comma(data.amount)}</Column>,
    },
    {
        title: "Status",
        index: "status",
        render: (_, data) => (
            <Column>
                <BookStatusRow status={data.bookStatus} />
            </Column>
        ),
    },
    {
        title: "Date Closed",
        index: "closeDate",
        render: (_, data) => <Column>{DateFormat(data.createdAt)}</Column>,
    },
];
