import { DateFormat } from "../../utils/helpers";
import Column from "../../sharedComponents/Column";


export const WaitListColumn = () => [
    {
        title: "Full Name",
        index: "Fname",
        render: (_, data) => <Column>{data?.fullName}</Column>,
    },
    {
        title: "Phone Number",
        index: "phone",
        render: (_, data) => <Column>{data?.phone}</Column>,
    },
    {
        title: "Email",
        index: "email",
        render: (_, data) => <Column>{data?.email}</Column>,
    },
    {
        title: "Sign-up Date",
        index: "dateCreated",

        render: (_, data) => <Column>{DateFormat(data?.createdAt)}</Column>,
    },
];
