import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";
import RoutesComponent from "./Routes";
import { getPersistor } from "@rematch/persist";
import store from "./redux/store";
import LoadingModal from "./sharedComponents/LoadingModal";
import ErrorModal from "./sharedComponents/ErrorModal";

const persistor = getPersistor();

function App() {
	return (
		<>
			<Provider store={store}>
				<LoadingModal />
				<ErrorModal />
				<PersistGate persistor={persistor}>
					<RoutesComponent />
				</PersistGate>
			</Provider>
		</>
	);
}
export default App;
