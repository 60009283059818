import { getToken } from "../../utils/helpers";
import handleAPI from "../index";

export const AddAuthorization = () => {
	const token = getToken();
	return { Authorization: `Bearer ${token}` };
};

export const authAPI = {
	test: (data) => handleAPI.get(data),
	signup: (data) => handleAPI.post("auth/create", data),
	login: (data) => handleAPI.post("auth/login", data),
	sendOtp: (data) => handleAPI.post("auth/send-code", data),
	verifyOtp: (data) => handleAPI.post("auth/verify", data),
	changePassword: (data) => handleAPI.post("auth/reset-password", data),
	savePin: (data, tokens) => handleAPI.post("profile/set-code", data, AddAuthorization(tokens)),
	createContributor: (data, tokens) =>
		handleAPI.post("customer/create", data, AddAuthorization(tokens)),
	adminCreateUser: (data, tokens) =>
		handleAPI.post("auth/adminSignup", data, AddAuthorization(tokens)),
	createPlan: (data, tokens) =>
		handleAPI.post("contribution/add-plan", data, AddAuthorization(tokens)),
};
