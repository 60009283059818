import React, { useState, useEffect } from 'react'
import Header from "../../../sharedComponents/Header"
import CustomTable from "../../../sharedComponents/CustomTable";
import { ClosedbooksCol } from './ClosedbooksCol';
import { dispatch } from "../../../redux/store";

export default function Closebooks() {
    const [closedBooks, setClosedBooks] = useState([])

    async function getClosedBooks() {
        try {
            const books = await dispatch.app.getClosedBooks()
            books && setClosedBooks(books)
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        getClosedBooks()
    }, [])

    return (
        <>
            <Header page="Closed Books" />
            <div className="flex flex-col items-start flex-1 px-6 py-4 bg-neutral-60">
                <CustomTable columns={ClosedbooksCol()} data={closedBooks} />
            </div>
        </>
    )
}