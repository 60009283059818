import { AbstractClass } from "./AbstractClass";
import { NextOfKin } from "./NextOfKin";

export class Customer extends AbstractClass {
	constructor(props) {
		super(props);
		this.id = props?.id;
		this.customerId = props?.customer?.id;
		this.userCode = props?.userCode;
		this.account = props?.account;
		this.email = props?.email || props?.emailAddress;
		this.phoneNumber = props?.phone || props?.phoneNumber;
		this.fullName = props?.fullName;
		this.firstName = props?.firstName;
		this.lastName = props?.lastName;
		this.occupation = props?.occupation;
		this.nextOfKin = new NextOfKin(props?.nextOfKin);
		this.contributions = props?.contributions || [];
	}
}
