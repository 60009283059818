import {string} from 'prop-types';

const initState = {
  message: null,
  cb: () => {
    console.log('error occurred');
  },
};
const reducerActions = {
  setState(state, payload) {
    return {
      message: payload.message,
      cb: payload.cb,
    };
  },
  clear() {
    return initState;
  },
};

export const error = {
  state: initState,
  reducers: reducerActions,
  effects: dispatch => ({
    setError(payload, state) {
      dispatch.error.setState({
        message: payload.message,
        cb: () => {
          payload.cb && payload.cb();
          dispatch.error.clear();
        },
      });
    },
  }),
};
