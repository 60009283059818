import { AbstractClass } from "./AbstractClass";

export class User extends AbstractClass {
  constructor(props) {
    super(props);
    this.name = props?.firstName + " " + props?.lastName;
    this.email = props?.email; 
    this.phone = props?.phone
    this.accountStatus = props?.accountStatus;
    this.type = props?.userType;
    this.status = props?.bvnConfirmed || "pending"
  }
}