import loadingPlugin from "@rematch/loading";
import { init } from "@rematch/core";

import * as models from "../model";
import { persistStorage } from "../persist";

const store = init({
	models: models,
	plugins: [loadingPlugin(), persistStorage],
});

export const { dispatch, getState } = store;

export default store;
