import React from "react";
import Header from "../../sharedComponents/Header/header";
import CustomTable from "../../sharedComponents/CustomTable";
import { CustomerColumn } from "./CustomerColumn";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { dispatch } from "../../redux/store";
import { Customer } from "../../classes/Customer";

export default function Customers() {
	const { customers, navigateToCustomer } = useCustomers();

	return (
		<>
			<Header page="Customer" desc="Manage your customers all in one place" />
			<div className="flex flex-col items-start flex-1 px-6 py-4 bg-neutral-60">
				<CustomTable
					columns={CustomerColumn()}
					onRowClick={(e) => navigateToCustomer(e.id)}
					data={customers}
				/>
			</div>
		</>
	);
}

const useCustomers = () => {
	const [customers, setCustomers] = useState([]);
	const navigate = useNavigate();

	const navigateToCustomer = (id) => {
		navigate(`/Customer/${id}`);
	};

	const fetchCustomerList = async () => {
		try {
			const customerList = await dispatch.app.getCustomers();
			const result = customerList.map((customer) => new Customer(customer));
			setCustomers(result);
		} catch (e) {
			console.log({ error: e });
		}
	};

	useEffect(() => {
		fetchCustomerList();
	}, []);

	return { customers, navigateToCustomer };
};
