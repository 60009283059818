import React from "react";
import "rodal/lib/rodal.css";
import Rodal from "rodal";
import { useSelector } from "react-redux";
import LoaderButton from "./Loader/LoaderButton";
import { dispatch } from "../redux/store";

export default function ErrorModal() {
	const error = useSelector((state) => state.error);
	const closeModal = () => dispatch.error.clear();
	return (
		<Rodal
			onClose={() => {}}
			visible={!!error.message}
			customStyles={{
				padding: 16,
				width: "max-content",
				maxWidth: "20rem",
				height: "max-content",
				maxHeight: "40rem",
				overflowY: "auto",
			}}
			closeMaskOnClick={false}
			showCloseButton={false}
			className="bg-transparent p-4 flex items-center justify-center flex-1">
			<div className="flex flex-col p-4 flex-1 items-center justify-center bg-white">
				<p className="text-sm" data-testid="error">
					{error.message}
				</p>
				<div className="flex justify-end mt-8 w-full">
					<button onClick={closeModal} className="!px-3 !py-2 btn-primary">
						Close
					</button>
				</div>
			</div>
		</Rodal>
	);
}
