import { useState } from "react";
import Header from "../../../sharedComponents/Header";
import { useEffect } from "react";
import { dispatch } from "../../../redux/store";
import { useNavigate } from "react-router-dom";
import CustomTable from "../../../sharedComponents/CustomTable";
import { MerchantColumn } from "./MerchantColumn";

export default function Merchant() {
	// const [category, setCategory] = useState("All");

	const { merchants, navigateToMerchant } = useMerchant();

	return (
		<>
			<Header page="Merchant" desc="Manage your customers all in one place" />
			<div className="flex flex-col items-start flex-1 px-6 py-4 bg-neutral-60">
				{/* <div className="flex items-center flex-row justify-between w-full">
					<form method="GET" className="w-3/5">
						<div className="relative text-[#091E42]">
							<BsSearch className="absolute top-[14px] left-4" />
							<input
								type="search"
								name="q"
								className="py-3 text-xs text-[#B3BAC5] w-4/5 bg-white border-2 border-[#A5ADBA] rounded-md pl-11 pr-4 focus:outline-none focus:bg-white focus:text-gray-900"
								placeholder="Search by merchant name"
								autoComplete="off"
							/>
						</div>
					</form>
					<div className="w-2/5 flex items-center justify-end">
						<button
							className={`flex px-4 mt-0 pl-2 gap-x-2 button_solid w-max py-3 flex-row button_solid`}>
							<BsPlus size={20} />
							<p>Add Merchant</p>
						</button>
					</div>
				</div> */}
				{/* <div className="flex flex-row py-4 gap-x-4 items-center">
					<p className="font-bold">Merchants</p>
					<div className="dropdown relative ">
						<button
							className="dropdown_button"
							type="button"
							id="dropdownMenuButton1"
							data-bs-toggle="dropdown"
							aria-expanded="false">
							<p className="font-bold text-[#091E42]">{category}</p>
							<AiFillCaretDown size={16} className="ml-4" />
						</button>
						<ul
							aria-labelledby="dropdownMenuButton1"
							className={`dropdown-menu dropdown_list`}>
							<li>
								<button onClick={() => category !== "All" && setCategory("All")}>
									All
								</button>
							</li>
							<li>
								<button
									onClick={() => category !== "Active" && setCategory("Active")}>
									Active
								</button>
							</li>
							<li>
								<button
									onClick={() =>
										category !== "Inactive" && setCategory("InActive")
									}>
									Inactive
								</button>
							</li>
						</ul>
					</div>
				</div> */}
				<CustomTable
					onRowClick={(e) => navigateToMerchant(e.id)}
					columns={MerchantColumn({ navigate: navigateToMerchant })}
					data={merchants}
				/>
			</div>
		</>
	);
}

const useMerchant = () => {
	const [merchants, setMerchants] = useState([]);
	const navigate = useNavigate();
	const navigateToMerchant = (id) => {
		navigate(`/merchant/${id}`);
	};

	const fetchMerchantList = async () => {
		try {
			const merchantList = await dispatch.app.getMerchants();
			merchantList && setMerchants(merchantList);
		} catch (e) {
			console.log({ error: e });
		}
	};

	useEffect(() => {
		fetchMerchantList();
	}, []);

	return { merchants, navigateToMerchant };
};
