import { useState, useEffect } from "react";
import {
  BsChevronLeft,
  BsChevronRight,
  BsDot,
} from "react-icons/bs";


import Header from "../../../sharedComponents/Header";
import { Table } from "antd"
import columns from "./kycColumns"
import { dispatch } from "../../../redux/store";
import { User } from "../../../classes/Users";


export default function Kyc() {
  const [users, setUsers] = useState([])
  const fetchAllUsers = async () => {
    try {
      const usersList = await dispatch.app.getAllUsers()
      const result = usersList.map((user) => new User(user));
      setUsers(result);
    } catch (e) {
      console.log({ error: e });
    }
  };

  useEffect(() => [
    fetchAllUsers()
  ], [])

  return (
    <>
      <Header desc="KYC of all users" page="Kyc" />
      <div className="flex flex-col items-start flex-1 px-6 py-4 bg-neutral-60">
        <div className="flex items-center flex-row justify-between w-full">
          <h3 className="text-lg text-[#091E42] font-bold">KYC</h3>
        </div>

        <div className="overflow-x-auto w-full h-full min-w-[50rem]">
          <Table dataSource={users} columns={columns} defaultFilteredValue={[{
            key: 'status',
            value: 'pending'
          }]} />
        </div>
      </div>
    </>
  );
}
